import React from 'react';
import { Page, Toolbar, BackButton} from "react-onsenui";
import '../../App.css';
import { UserLoginStatus, AppController } from '../../App'
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

export class DetailPageBase extends React.Component<any, any>{
    public PageTitle: string = 'public PageTitle: string をオーバーライドしてください';
    private previousToolbarRenderer: any;
    constructor(p: any, s: any) {
        super(p);
        this.state = {}

        // this.previousToolbarRenderer = this.props.getCurrentToolbarRenderer();
        this.previousToolbarRenderer = AppController.getInstance().getCurrentToolbarRenderer();
        AppController.getInstance().registerToolbarRenderer(this.props.index, () => {
            return (
                <Toolbar>
                    <div className='left'><BackButton onClick={this.onBackButton}>戻る</BackButton></div>
                    <div className='center'>{this.PageTitle}</div>
                    <div className='right'><UserLoginStatus></UserLoginStatus></div>
                </Toolbar>
            );
        })
        AppController.getInstance().applyToolbarRendererNow();
    }

    onBackButton = () => {
        AppController.getInstance().registerToolbarRenderer(this.props.index, this.previousToolbarRenderer)
        // this.props.registerToolbarRenderer(this.props.index, this.previousToolbarRenderer);
        AppController.getInstance().applyToolbarRendererNow();
        // this.props.applyToolbarRendererNow();
        this.props.navigator.popPage();
    }

    render() {
        return (
            <Page>
                This is super class!!<br />
                Please override this method!!
            </Page>
        );
    }
}