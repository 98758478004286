import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, ProgressCircular, Modal, Page, Toolbar, BackButton, Button, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../icon/right-arrow1.svg';
import '../../App.css';
import { DetailPageBase } from './PageBase'
import { APIs } from '../../model/MarsAPIs'
import { jsxAttribute } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import '../../css/mars.css'

class UserInfoDetailElement extends React.Component<any>{
    constructor(p:any){
        super(p);
    }
    render(){

        // test
        // this.props.userInfo.divisions = [
        //     {user_id:'kudon', division_id:'111111', division_name:'管理部'},
        //     {user_id:'kudon', division_id:'111112', division_name:'管理部２'},
        //     {user_id:'kudon', division_id:'111113', division_name:'管理部3'}
        // ]

        const kvs = [
            {k:'ユーザーID', v:this.props.userInfo.user_id},
            {k:'氏', v:this.props.userInfo.last_name},
            {k:'名', v:this.props.userInfo.first_name},
            {k:'氏(かな)', v:this.props.userInfo.last_name_kana},
            {k:'名(かな)', v:this.props.userInfo.first_name_kana},
            {k:'Eメール (プライマリ)', v:<a href={"mailto:"+this.props.userInfo.email_address}>{this.props.userInfo.email_address}</a>},
            {k:'電話番号', v:<a href={'tel:'+this.props.userInfo.phone_number}>{this.props.userInfo.phone_number}</a>},
            {k:'権限レベル', v:this.props.userInfo.permission_level.toString()}
        ]
        let trecords = kvs.map(element=>{
            return(
                <tr key={element.k + element.v}>
                    <th>
                        {element.k}
                    </th>
                    <td className='data'>
                        {element.v}
                    </td>
                </tr>
            )
        })
        let divisionelems:any[] = [];
        for(let i=0; i<this.props.userInfo.divisions.length; ++i){
            if(i==0){
                divisionelems.push(
                    <tr key={'divisionwrapper'+this.props.userInfo.user_id+i}>
                        <th rowSpan={this.props.userInfo.divisions.length}>
                            所属部署
                        </th>
                        <td className='data'>
                            {this.props.userInfo.divisions[i].division_name}
                        </td>
                    </tr>
                )
            }else{
                divisionelems.push(
                    <tr key={'divisionwrapper'+this.props.userInfo.user_id}>
                        <td className='data'>
                            {this.props.userInfo.divisions[i].division_name}
                        </td>
                    </tr>
                )
            }
        }
        return(
            <div>
                <table className='tlayout1'>
                    <tbody>
                        {trecords}
                        {divisionelems}
                        {/* <tr key={'divisionwrapper'+this.props.userInfo.user_id}>
                            <th rowSpan={2}>
                                所属部署
                            </th>
                            <td>
                                b1
                            </td>
                        </tr>
                        <tr>
                            <td>
                                b2
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        )
    }
}

export class UserAccountInfoPage extends DetailPageBase{
    public PageTitle: string = 'ユーザー情報';
    constructor(p:any, s:any){
        super(p, s);

        if(this.props.userInfo === undefined){
            this.state = {userInfo:null}

            APIs.getInstance().getUsers([this.props.user_id])
                .then(json=>{
                    this.setState({
                        userInfo:json[0]
                    })
                })
        }else{
            this.state = {userInfo:this.props.userInfo}
        }
    }
    render() {
        if(this.state.userInfo){
            let kickButton = <div></div>
            if(this.props.kickable){
                kickButton = <Button>ログイン状態解除</Button>
            }
            let deleteButton = <div></div>
            if(this.props.deletable){
                deleteButton = <Button>ユーザー削除</Button>
            }

            return(
                <Page>
                    <div style={{marginTop:'10px'}}>
                        
                    </div>
                    <UserInfoDetailElement userInfo={this.state.userInfo} />
                    {kickButton}
                    {deleteButton}
                </Page>
            )
        }else{
            return(
                <Page>
                    <div style={{width:'100%', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div>
                            <ProgressCircular
                                indeterminate
                            ></ProgressCircular>
                            読み込み中...
                        </div>
                    </div>
                </Page>
            )
        }
    }
}