import React from 'react';
import { ChangeEvent } from 'react'
import { Icon, Page, Button, Select, Input, Toast, Checkbox, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import '../App.css';
import { APIs } from '../model/MarsAPIs'
import { jsxAttribute } from '@babel/types';
import '../css/csshake-shake-horizontal.css';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

// class InvalidNotation extends React.Component<any, any>{
//     render() {
//         if(this.props.invalid_user_id)
//         return (
//             <label>this.props.message</label>
//         )
//     }
// }

interface LoginPageProps {
    onLoginComplete: any
}

export default class LoginPage extends React.Component<LoginPageProps, any>{
    private inputStateNormal: string = ''
    private inputStateShake: string = 'shake-horizontal shake-constant'
    constructor(props: any) {
        super(props);
        this.state = {
            invalid_user_id: false,
            invalid_password: false,
            login_error: false,
            login_message: "",
            user_id: "",
            password: "",
            loggedIn: false,
            inputState: this.inputStateNormal
        }
    }
    login = () => {

        APIs.getInstance().Login(
            this.state.user_id,
            this.state.password,
            () => {
                this.setState({ loggedIn: true });
                this.props.onLoginComplete();
            },
            (err: any) => {
                this.setState({
                    'login_error': true,
                    'login_message': err.message,
                    password: '',
                    inputState: this.inputStateShake
                })
                setTimeout(
                    this.resetPasswordInputState,
                    300
                )
            }
        );
        // console.log(this.state);
    }

    resetPasswordInputState = () => {
        this.setState({
            inputState: this.inputStateNormal
        })
    }

    render_error_message = () => {
        if (this.state.login_error) {
            let msg: string;
            if (this.state.login_message == 'Invalid UserId or Password') {
                msg = 'ユーザーIDまたはパスワードが無効です'
            } else {
                msg = this.state.login_message
            }
            return (
                <div style={{ color: "red", height: "1em" }}>
                    <label>{msg}</label>
                </div>
            )
        } else {
            return (<div style={{ color: "red", height: "1em" }}></div>)
        }
    }

    render() {
        const changePasswordPageUrl: string|undefined = process.env.REACT_APP_CHANGE_PASSWORD_PAGE_URL;
        return (
            <Page>
                {/* <Toast isOpen={this.state.toast_is_open} animation="fall">{this.state.toast_message}</Toast> */}
                {/* <Toast isOpen={this.state.toast_is_open} animation="fall">{ errmsg }</Toast> */}

                <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ textAlign: "center" }}>
                        <div>{process.env.REACT_APP_SERVICE_NAME}</div>
                        <div><b>C</b>omputer <b>A</b>ided <b>S</b>afe-report <b>E</b>nvironment</div>
                        {/* <div><img src={logo} className="App-logo"></img></div> */}
                        <div className="App-logo"><img src={logo} /></div>

                        {/* <div>Better Environment for SAFE-confirmation</div>
                        <div style={{marginTop:'20px'}}>BE SAFE</div> */}
                        {this.render_error_message()}
                        <div style={{ textAlign: "center", marginTop: "30px" }}>
                            <Input
                                className={this.state.inputState}
                                id="user_id"
                                modifier="material"
                                placeholder="UserID"
                                float
                                value={this.state.user_id}
                                onChange={(e) => { this.setState({ "user_id": e.target.value, "login_error": false }) }}
                            >
                            </Input>
                        </div>
                        <div style={{ textAlign: "center", marginTop: "30px" }}>
                            <Input
                                className={this.state.inputState}
                                id="password"
                                modifier="material"
                                type="password"
                                placeholder="Password"
                                float
                                value={this.state.password}
                                onChange={(e) => { this.setState({ "password": e.target.value, "login_error": false }) }}
                            >
                            </Input>
                        </div>
                        <p style={{ textAlign: "center", marginTop: "30px" }}>
                            <Button onClick={this.login}>Sign in</Button>
                        </p>
                        <div>
                            <a 
                                href={changePasswordPageUrl}
                                target='_blank'
                                rel="noopener noreferrer"
                            >
                                ℹ️パスワードを忘れた/変更したい場合はこちら
                            </a>
                        </div>
                    </div>
                </div>
            </Page>
        )
    }
}