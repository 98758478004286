import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, ProgressCircular, Modal, Page, Toolbar, BackButton, Button, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../../icon/right-arrow1.svg';
import '../../App.css';
import '../../css/mars.css'
import { DetailPageBase } from './PageBase'
import { APIs } from '../../model/MarsAPIs'
import { jsxAttribute } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import { AppController } from '../../App';
import { UserAccountInfoPage } from './UserAccountInfoPage'

export class EarthQuakeHistoryPage extends DetailPageBase {
    public PageTitle = '地震検知履歴'

    constructor(p: any, s: any) {
        super(p, s);
        this.state = {
            limit: 50,
            history: null
        }
    }

    handleClick = () => {

    }

    render() {
        return (
            <Page>
                <div>
                    システムが気象庁から受信した地震情報を表示します
                </div>
                <div>
                    最新
                    <input
                        onChange={(ev) => this.setState({ limit: ev.target.value })}
                        value={this.state.limit}
                    ></input>
                    件
                </div>
                <div>
                    <button
                        onClick={this.handleClick}
                    >
                        表示
                    </button>
                </div>
            </Page>
        )
    }
}