import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, Icon, AlertDialog, ProgressCircular, Page, Toolbar, BackButton, Button, Modal, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../icon/right-arrow1.svg';
import '../App.css';

import { UserLoginStatus, AppController, App, AppCallbackType } from '../App'
import { ThreadViewer } from './ThreadList'
import { SequenceSelecter } from './SubComponents/SequenceSelecter'
import { APIs, UserInfo, getParam } from '../model/MarsAPIs'
import { PersonSafetyConst, MessageType, ThreadStatus, CycleColors } from '../model/constants'
import { jsxAttribute } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import '../css/mars.css';
import { threadId } from 'worker_threads';

const POLLING_INTERVAL = 30000;
// const POLLING_INTERVAL = 1000000;

class NavigatorChildBase extends React.Component<any, any>{
    private previousToolbarRenderer: any;
    public toolbarRenderer: () => void = () => {
        return (
            <Toolbar>
                <div className='left'><BackButton onClick={this.onBackButton}></BackButton></div>
                <div className='center'>{'安否集約'}</div>
                <div className='right'><UserLoginStatus></UserLoginStatus></div>
            </Toolbar>
        );
    }
    constructor(p: any, s: any) {
        super(p);
        this.state = {
            pageTitle: ''
        }
        console.log('!!!!!!!!!!!!!!!!!!!!!!NavigatorBase::ctor');
        console.log(p);

        this.previousToolbarRenderer = AppController.getInstance().getCurrentToolbarRenderer();
        // this.props.registerToolbarRenderer(this.props.index , this.toolbarRenderer);
        // this.props.applyToolbarRendererNow();
    }

    onBackButton = () => {
        console.log(this.props.index)
        if (!this.props.navigator.isRunning()) {
            AppController.getInstance().registerToolbarRenderer(this.props.index, this.previousToolbarRenderer);
            AppController.getInstance().applyToolbarRendererNow();
            this.props.navigator.popPage();
        }
    }

    render() {
        return (
            <Page>
                Please overload this method!!
            </Page>
        );
    }
}

function getInfoById(userId: string, source: any) {
    for (var info of source) {
        if (info.user_id === userId) {
            return info;
        }
    }
    return null
}

class UserInfoRow extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
    }
    // update = () => {
    //     APIs.getInstance().getThreadSummary(this.props.metadata.thread_ids[this.props.userId])
    //         .then(json=>{
    //             let nthrep=0;
    //             for(let res of json.responses){
    //                 if(res.types & MessageType.UserStatus){
    //                     nthrep++;
    //                 }
    //             }
    //             this.setState({nth_report:nthrep})
    //         })
    // }
    onClick = () => {
        // Move to threadViewer
        console.log(this.props.metadata)
        APIs.getInstance().getThreadSummary(this.props.metadata.thread_ids[this.props.userId])
            .then(json => {
                console.log(this.props.navigator)
                this.props.navigator.pushPage({
                    component: ThreadViewer,
                    title: 'ThreadViewer',
                    _props: Object.assign(
                        {
                            thread_summary: json,
                            selfpolling: true
                        },
                        this.props
                    )
                });
            })
            .catch(err => {
                AppController.getInstance().notify(err);
            })
    }
    render() {
        let nth_report_notification: string = '';
        if (this.props.nthReports[this.props.userId] && this.props.nthReports[this.props.userId] >= 2) {
            nth_report_notification = this.props.nthReports[this.props.userId] + '報';
        }
        if (this.props.source === null) {
            return (
                <tr style={{ display: 'flex', flexDirection: 'row' }} key={this.props.userId + 'uir'}>
                    <th>
                        <div><ProgressCircular indeterminate></ProgressCircular></div>
                        <div>{this.props.userId}</div>
                    </th>
                </tr>
            )
        } else {
            // let style = Object.assign({ display: 'flex', flexDirection: 'row' }, this.props.style)
            let uinfo = getInfoById(this.props.userId, this.props.source);

            if (uinfo === null) {
                return (
                    <tr
                        key={this.props.userId + 'uir'}
                        onClick={this.onClick}
                    >
                        {/* <div>{this.props.userId}</div> */}
                        <th>
                            <div>
                                <div
                                    className='notification'
                                // style={{opacity:0.5, fontSize:'10px', position:'absolute', bottom:'8px', right:'10%'}}
                                >
                                    {nth_report_notification}
                                </div>
                                <div>{this.props.userId + '(退会)'}</div>

                                {/* <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img> */}
                            </div>
                        </th>
                        {/* <div style={{ width: '10em' }}><a href={"tel:" + uinfo.phone_number + ''}>電話する</a></div> */}
                        <td className='data'>
                            <div>
                                <div>
                                    {this.props.specialComment[this.props.userId]}
                                </div>
                                <div style={{ width: '1em', marginLeft: 'auto' }}>
                                    <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            } else {
                // console.log(this.props)
                return (
                    <tr
                        key={this.props.userId + 'uir'}
                        onClick={this.onClick}
                    >
                        {/* <div>{this.props.userId}</div> */}
                        <th>
                            <div>
                                <div
                                    className='notification'
                                // style={{opacity:0.5, fontSize:'10px', position:'absolute', bottom:'8px', right:'10%'}}
                                >
                                    {nth_report_notification}
                                </div>
                                <div>{uinfo.last_name + ' ' + uinfo.first_name}</div>

                                {/* <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img> */}
                            </div>
                        </th>
                        {/* <div style={{ width: '10em' }}><a href={"tel:" + uinfo.phone_number + ''}>電話する</a></div> */}
                        <td className='data'>
                            <div>
                                <div>
                                    {this.props.specialComment[uinfo.user_id]}
                                </div>
                                <div style={{ width: '1em', marginLeft: 'auto' }}>
                                    <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            }
        }
    }
}

class AggregationDetail extends NavigatorChildBase {
    public toolbarRenderer: () => void = () => {
        return (
            <Toolbar>
                <div className='left'><BackButton onClick={this.onBackButton}>戻る</BackButton></div>
                <div className='center'>{this.state.pageTitle}</div>
                <div className='right'><UserLoginStatus></UserLoginStatus></div>
            </Toolbar>
        );
    }
    constructor(props: any, states: any) {
        super(props, states);
        this.state = {
            pageTitle: this.props.dataTitle,
            lastUpdatedAt: this.props.lastUpdatedAt,
            data: this.props.data,
            ss: this.props.ss,
            usersInfo: null,
            elapsedTimeLastUpdatedString: ''
        }
        AppController.getInstance().registerToolbarRenderer(this.props.index, this.toolbarRenderer);
        AppController.getInstance().applyToolbarRendererNow();
        this.props.registerCallbackWhenComponentDidUpdate(this.parentDidUpdated)

        // Get all user info
        APIs.getInstance().getUsers([])
            .then(json => {
                this.setState({
                    usersInfo: json
                })
            })
            .catch(err => {
                AppController.getInstance().notify(err)
            })

        setInterval(() => {
            this.updateElapsedTimeLastUpdatedString();
        }, 2000);

    }

    updateElapsedTimeLastUpdatedString = () => {
        this.setState({
            elapsedTimeLastUpdatedString: getElapsedTimeString(this.state.lastUpdatedAt)
        })
    }

    componentWillUnmount() {
        this.props.unregisterCallbackWhenComponentDidUpdate();
    }
    parentDidUpdated = (props: any) => {
        this.setState({
            lastUpdatedAt: props.lastUpdatedAt,
            data: props.data,
            specialComment: props.specialComment
        })
    }
    renderElement(label: string, color: string, data: any) {

        let userList: JSX.Element[] = []
        for (let user of data) {
            userList.push(
                <UserInfoRow
                    userId={user[0]}
                    source={this.state.usersInfo}
                    specialComment={this.props.specialComment}
                    metadata={this.props.metadata}
                    nthReports={this.props.nthReports}
                    navigator={this.props.navigator}
                    index={this.props.index}
                ></UserInfoRow>)
        }
        if (data.length === 0) {
            userList.push(
                <div>(該当者なし)</div>
            )
        }

        return (
            <div>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: '20px'
                }}>
                    <div style={{
                        width: '0.8em',
                        height: '0.8em',
                        borderRadius: '0.4em',
                        backgroundColor: color
                    }}></div>
                    <div>{label}</div>
                </div>
                <table className='tlayout2' style={{ marginLeft: '0.8em' }}>
                    <tbody>
                        {userList}
                    </tbody>
                </table >
            </div>
        )
    }
    render() {
        let elements: JSX.Element[] = [];
        console.log(this.state.data);
        let labels_ = JSON.parse(JSON.stringify(this.props.labels));
        if (this.props.unregisterable) {
            labels_['unregistered'] = ['未登録', 'red']
        }
        for (var key of Object.keys(labels_).reverse()) {
            let label = labels_[key][0];
            let color = labels_[key][1];
            let data;
            if (this.state.data[key] === undefined) {
                data = [];
            } else {
                data = this.state.data[key];
            }
            elements.push(this.renderElement(label, color, data))
        }
        return (
            <Page>
                <div style={{ width: '100%', textAlign: 'right', marginTop: '5px' }}>
                    <div style={{ textAlign: 'right' }}>
                        {'最終更新:' + this.state.elapsedTimeLastUpdatedString}
                    </div>
                </div>
                {/* {JSON.stringify(this.state.data)}
                {JSON.stringify(labels_)} */}
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div style={{ width: '95%' }}>
                        {elements}
                    </div>
                </div>
            </Page>
        )
    }

}

class AggrigatedBarElement extends React.Component<any, any>{
    private callbackWhenComponentDidUpdate: any | null = null;
    constructor(props: any) {
        super(props);
        this.state = {}
    }
    componentDidUpdate(prevProps: any, prevStates: any, snapshot: any) {
        if (this.callbackWhenComponentDidUpdate != null) {
            this.callbackWhenComponentDidUpdate(this.props);
        }
    }
    registerCallbackWhenComponentDidUpdate = (func: any) => {
        this.callbackWhenComponentDidUpdate = func;
    }
    unregisterCallbackWhenComponentDidUpdate = () => {
        this.callbackWhenComponentDidUpdate = null;
    }
    onClick = () => {
        this.props.navigator.pushPage({
            component: AggregationDetail,
            title: 'AggrigationDetail',
            _props: Object.assign(
                {
                    index: this.props.index,
                    registerCallbackWhenComponentDidUpdate: this.registerCallbackWhenComponentDidUpdate,
                    unregisterCallbackWhenComponentDidUpdate: this.unregisterCallbackWhenComponentDidUpdate,
                    specialComment: this.props.specialComment,
                    metadata: this.props.metadata,
                    nthReports: this.props.nthReports
                },
                this.props
            )
        });
    }

    render() {

        let total: number = 0;
        for (var k in this.props.data) {
            total += this.props.data[k].length
        }

        let barElems: JSX.Element[] = [];
        let labelElems: JSX.Element[] = [];
        for (var key in this.props.labels) {
            let widthpct: number;
            let nPersons: number;
            if (this.props.data[key] === undefined) {
                continue;
                nPersons = 0;
                widthpct = 0.0;
            } else {
                nPersons = this.props.data[key].length
                widthpct = (nPersons / total) * 100
            }
            let label = this.props.labels[key][0];
            let backgroundColor: string;
            let lineColor: string | undefined = undefined;
            if (Array.isArray(this.props.labels[key][1])) {
                backgroundColor = this.props.labels[key][1][0];
                lineColor = this.props.labels[key][1][1];
            } else {
                backgroundColor = this.props.labels[key][1];
            }
            labelElems.push(
                <div
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    key={label}
                >
                    {/* color circle */}
                    <div style={{
                        backgroundColor: backgroundColor,
                        border: 'solid 1px ' + lineColor,
                        boxSizing: 'border-box',
                        // color:lineColor,
                        // borderWidth: '1px',
                        width: '0.8em',
                        height: '0.8em',
                        borderRadius: '0.4em',
                        marginLeft: '20px',
                    }}
                    >
                    </div>

                    {/* label  */}
                    <div style={{ fontSize: '0.8em' }}>
                        <a style={{ marginRight: '0.5em' }}>
                            {label}
                        </a>
                        <a style={{ color: 'gray' }}>
                            {nPersons + '人'}
                        </a>
                    </div>
                </div>
            )
            barElems.push(
                <div
                    style={{
                        width: widthpct.toString() + '%',
                        backgroundColor: backgroundColor,
                        borderTop: 'solid 1px ' + lineColor,
                        borderBottom: 'solid 1px ' + lineColor,
                        borderRight: 'solid 1px ' + lineColor,
                        boxSizing: 'border-box',
                        height: '10px'
                    }}
                    key={label + '_bar'}
                >
                </div>
            )
        }

        return (
            <div>
                <div
                    style={{ width: '100%' }}
                    onClick={this.onClick}
                >
                    <div style={{ marginLeft: '15px' }}>
                        {this.props.dataTitle}
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        {labelElems}
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        {barElems}
                    </div>
                </div>
            </div>
        )
    }
}

function getElapsedTimeString(lastUpdatedUnixTime: number): string {
    let elapsedsec = (Date.now() - lastUpdatedUnixTime) / 1000
    if (elapsedsec < 60) {
        return Math.floor(elapsedsec) + '秒前'
    } else {
        return Math.floor(elapsedsec / 60) + '分前'
    }
}

class AggrigationView extends React.Component<any, any>{
    private updateElapsedTimeIntervalId: NodeJS.Timeout | null = null
    constructor(p: any, s: any) {
        super(p);

        this.state = {
            elapsedTimeLastUpdatedStr: ''
        }

        this.updateElapsedTimeIntervalId = setInterval(() => {
            this.updateElapsedTimeLastUpdateStr();
        }, 2000
        )
    }

    componentWillUnmount = () => {
        if (this.updateElapsedTimeIntervalId != null) {
            clearTimeout(this.updateElapsedTimeIntervalId);
            this.updateElapsedTimeIntervalId = null;
        }

    }

    updateElapsedTimeLastUpdateStr = () => {
        // getElapsedTimeString(this.props.lastUpdatedAt)
        this.setState({
            elapsedTimeLastUpdatedString: getElapsedTimeString(this.props.lastUpdatedAt)
        })
    }

    render() {
        if (this.props.aggrigatedData === null) {
            return (
                <div>
                    <ProgressCircular></ProgressCircular>
                </div>
            )
        } else {
            const ps = PersonSafetyConst.PersonSafetySN;
            const fs = PersonSafetyConst.FamilySafetySN;
            const lc = PersonSafetyConst.LocationSN;
            const ds = PersonSafetyConst.DamageSituationSN;
            const cw = PersonSafetyConst.CoopCompanyWorkersSN;
            const wb = PersonSafetyConst.WorkabilitySN;
            const marginub = '7px';
            return (
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{}}>各項目から詳細を閲覧可能</div>
                        <div style={{ flex: 1, textAlign: 'right', fontSize: '0.6em' }}>最終更新：{this.state.elapsedTimeLastUpdatedString}</div>
                    </div>
                    <div style={{ width: '95%', border: 'solid 1px darkgray', borderRadius: '3px', justifyContent: 'center' }}>
                        <div style={{ marginBottom: marginub }}>
                            <AggrigatedBarElement
                                data={this.props.aggrigatedData.user_status_registered}
                                specialComment={this.props.aggrigatedData.special_comment}
                                metadata={this.props.aggrigatedData.meta}
                                nthReports={this.props.aggrigatedData.nth_reports}
                                dataTitle='安否登録状況'
                                labels={{ 'true': ['登録済', 'green'], 'false': ['未登録', ['white', 'green']] }}
                                key='user_status_registered'
                                lastUpdatedAt={this.props.lastUpdatedAt}
                                navigator={this.props.navigator}
                                index={this.props.index}
                            >
                            </AggrigatedBarElement>
                        </div>
                        <hr
                            className='major-splitter'
                        />
                        {/* <div style={{ marginTop: marginub, marginBottom: marginub }}>
                            <AggrigatedBarElement
                                data={this.props.aggrigatedData.thread_status}
                                specialComment={this.props.aggrigatedData.special_comment}
                                metadata={this.props.aggrigatedData.meta}
                                dataTitle='上長による安否確認状況'
                                labels={{ 'closed': ['確認済', 'green'], 'open': ['未確認', 'red'] }}
                                key='thread_status'
                                lastUpdatedAt={this.props.lastUpdatedAt}
                                navigator={this.props.navigator}
                                index={this.props.index}
                            >
                            </AggrigatedBarElement>
                        </div>
                        <hr style={{ width: '90%', margin: '0 auto 0 auto', padding: '0 auto 0 auto' }}></hr> */}
                        <div style={{ marginTop: marginub, marginBottom: marginub }}>
                            <AggrigatedBarElement
                                data={this.props.aggrigatedData.person_safety}
                                specialComment={this.props.aggrigatedData.special_comment}
                                metadata={this.props.aggrigatedData.meta}
                                nthReports={this.props.aggrigatedData.nth_reports}
                                dataTitle='本人安否'
                                labels={{
                                    0: [ps[0], 'green'],
                                    1: [ps[1], 'yellow'],
                                    2: [ps[2], 'red']
                                }}
                                key='person_safety'
                                lastUpdatedAt={this.props.lastUpdatedAt}
                                navigator={this.props.navigator}
                                index={this.props.index}
                            >
                            </AggrigatedBarElement>
                        </div>
                        <hr style={{ width: '90%', margin: '0 auto 0 auto', padding: '0 auto 0 auto' }}></hr>
                        <div style={{ marginTop: marginub, marginBottom: marginub }}>
                            <AggrigatedBarElement
                                data={this.props.aggrigatedData.family_safety}
                                specialComment={this.props.aggrigatedData.special_comment}
                                metadata={this.props.aggrigatedData.meta}
                                nthReports={this.props.aggrigatedData.nth_reports}
                                dataTitle='家族安否'
                                labels={{
                                    0: [fs[0], 'green'],
                                    1: [fs[1], 'yellow'],
                                    2: [fs[2], 'red'],
                                    3: [fs[3], 'purple']
                                }}
                                key='family_safety'
                                lastUpdatedAt={this.props.lastUpdatedAt}
                                navigator={this.props.navigator}
                                index={this.props.index}
                            >
                            </AggrigatedBarElement>
                        </div>
                        <hr style={{ width: '90%', margin: '0 auto 0 auto', padding: '0 auto 0 auto' }}></hr>
                        <div style={{ marginTop: marginub, marginBottom: marginub }}>
                            <AggrigatedBarElement
                                data={this.props.aggrigatedData.location}
                                specialComment={this.props.aggrigatedData.special_comment}
                                metadata={this.props.aggrigatedData.meta}
                                nthReports={this.props.aggrigatedData.nth_reports}
                                dataTitle='現在地'
                                labels={{
                                    0: [lc[0], 'green'],
                                    1: [lc[1], 'blue'],
                                    2: [lc[2], 'orange'],
                                    3: [lc[3], 'purple']
                                }}
                                key='location'
                                lastUpdatedAt={this.props.lastUpdatedAt}
                                navigator={this.props.navigator}
                                index={this.props.index}
                            >
                            </AggrigatedBarElement>
                        </div>
                        <hr style={{ width: '90%', margin: '0 auto 0 auto', padding: '0 auto 0 auto' }}></hr>
                        <div style={{ marginTop: marginub, marginBottom: marginub }}>
                            <AggrigatedBarElement
                                data={this.props.aggrigatedData.damage_situation}
                                specialComment={this.props.aggrigatedData.special_comment}
                                metadata={this.props.aggrigatedData.meta}
                                nthReports={this.props.aggrigatedData.nth_reports}
                                dataTitle='物的被害'
                                labels={{
                                    0: [ds[0], 'green'],
                                    1: [ds[1], 'red'],
                                    2: [ds[2], 'yellow']
                                }}
                                key='damage_situation'
                                lastUpdatedAt={this.props.lastUpdatedAt}
                                navigator={this.props.navigator}
                                index={this.props.index}
                            >
                            </AggrigatedBarElement>
                        </div>
                        <hr style={{ width: '90%', margin: '0 auto 0 auto', padding: '0 auto 0 auto' }}></hr>
                        <div style={{ marginTop: marginub, marginBottom: marginub }}>
                            <AggrigatedBarElement
                                data={this.props.aggrigatedData.coop_company_workers}
                                specialComment={this.props.aggrigatedData.special_comment}
                                metadata={this.props.aggrigatedData.meta}
                                nthReports={this.props.aggrigatedData.nth_reports}
                                dataTitle='協力会社社員安否'
                                labels={{
                                    0: [cw[0], 'blue'],
                                    1: [cw[1], 'green'],
                                    2: [cw[2], 'red'],
                                    3: [cw[3], 'purple']
                                }}
                                key='coop_company_workers'
                                lastUpdatedAt={this.props.lastUpdatedAt}
                                navigator={this.props.navigator}
                                index={this.props.index}
                            >
                            </AggrigatedBarElement>
                        </div>
                        <hr style={{ width: '90%', margin: '0 auto 0 auto', padding: '0 auto 0 auto' }}></hr>
                        <div style={{ marginTop: marginub }}>
                            <AggrigatedBarElement
                                data={this.props.aggrigatedData.workability}
                                specialComment={this.props.aggrigatedData.special_comment}
                                metadata={this.props.aggrigatedData.meta}
                                nthReports={this.props.aggrigatedData.nth_reports}
                                dataTitle='出社可否'
                                labels={{
                                    0: [wb[0], 'green'],
                                    1: [wb[1], 'yellow'],
                                    2: [wb[2], 'darkorange'],
                                    3: [wb[3], 'red'],
                                    4: [wb[4], 'purple']
                                }}
                                key='workability'
                                lastUpdatedAt={this.props.lastUpdatedAt}
                                navigator={this.props.navigator}
                                index={this.props.index}
                            >
                            </AggrigatedBarElement>
                        </div>
                    </div>
                </div>

            )
        }
    }
}

export class SafetyConfirmationAggrigationPage extends React.Component<any, any>{
    private callbacksWhenPageActivated: { [key: string]: any } = {};
    // private static instance: SafetyConfirmationAggrigationPage|null = null;
    // static getInstance(): SafetyConfirmationAggrigationPage {
    //     if (!this.instance) {
    //         console.log('call constructor');
    //         this.instance = new SafetyConfirmationAggrigationPage({});
    //     }
    //     return this.instance;
    // }
    private pollingIntervalId: NodeJS.Timer | null = null;
    constructor(props: any) {
        super(props);
        this.state = {
            dataLastUpdatedAt: null,
            selectedSequenceId: null,
            aggrigatedData: null,
            // intervalId: null
        }
        AppController.getInstance().registerAppCallback(AppCallbackType.PageActivated, this.props.index, this.onPageActivate);
        AppController.getInstance().registerAppCallback(AppCallbackType.PageDeactivated, this.props.index, this.onPageDeactivate)

        this.registerPollingUpdate();
    }
    componentWillUnmount = () => {
        this.unregisterPollingUpdate();
    }
    onPageActivate = () => {
        console.log('onPageActivated');
        this.activatePage();

        this.updateAggrigationData();
        this.registerPollingUpdate();
    }
    onPageDeactivate = () => {
        console.log('onPageDeactivated')
        this.unregisterPollingUpdate();
    }
    registerPollingUpdate = () => {
        if (this.pollingIntervalId != null) {
            this.unregisterPollingUpdate();
        }
        let intervalId = setInterval(this.updateAggrigationData, POLLING_INTERVAL);
        this.pollingIntervalId = intervalId;
    }
    unregisterPollingUpdate = () => {
        if (this.pollingIntervalId != null) {
            clearInterval(this.pollingIntervalId)
            this.pollingIntervalId = null;
        }
    }
    activatePage = () => {
        // Call callback
        for (const key in this.callbacksWhenPageActivated) {
            this.callbacksWhenPageActivated[key]();
        }
    }
    onSequenceSelected = (seq: number) => {
        this.setState({
            selectedSequenceId: seq
        })
        this.updateAggrigationData()
    }
    registerCallbackForUpdate = (key: string, func: any) => {
        this.callbacksWhenPageActivated[key] = func
    }
    updateAggrigationData = () => {
        console.log('AggrigationData updating...');

        if (this.state.selectedSequenceId === null) {
            this.setState({
                aggrigatedData: null
            })
        } else {
            APIs.getInstance().getAggrigationData(this.state.selectedSequenceId)
                .then(json => {
                    this.setState({
                        aggrigatedData: json
                    })
                })
                .then(() => {
                    this.setState({
                        dataLastUpdatedAt: Date.now()
                    });
                })
                .catch(err => {
                    AppController.getInstance().notify(err);
                })
        }
    }

    render() {
        return (
            <Page>
                <div>
                    <SequenceSelecter
                        onSequenceSelected={this.onSequenceSelected}
                        registerCallbackForUpdate={this.registerCallbackForUpdate}
                        withClosedSequenceCheckbox
                    >
                    </SequenceSelecter>
                    {/* <input type='checkbox' defaultValue='checked' value='asdf'></input> */}
                </div>
                <div>
                    <AggrigationView
                        aggrigatedData={this.state.aggrigatedData}
                        lastUpdatedAt={this.state.dataLastUpdatedAt}
                        navigator={this.props.navigator}
                        index={this.props.index}
                    >
                    </AggrigationView>
                </div>
            </Page>
        )
    }
}

export default class SafetyConfirmationAggrigationPageNavigator extends React.Component<any, any>{
    // private static instance: SafetyConfirmationAggrigationPageNavigator|null = null;
    // static getInstance(): SafetyConfirmationAggrigationPageNavigator {
    //     if (!this.instance) {
    //         console.log('call constructor');
    //         this.instance = new SafetyConfirmationAggrigationPageNavigator({});
    //     }
    //     return this.instance;
    // }
    constructor(props: any) {
        super(props);
        // console.log('SafetyConfirmationPageNavigator::constructor');
        // console.log(props);
    }

    // renderPageのPropsに与える関数
    // ・現在のrouteの情報(route)とnavigatorオブジェクトを引数にとる
    // ・navigator.pushPage(route)される度にrenderPageが実行される。
    renderPage(route: any, navigator: any) {
        console.log('SafetyConfirmationPageNavigator::renderPage()')
        // console.log(route.component)
        // console.log(this.props)
        // console.log(route.props)
        // console.log(route)
        // 現在のページのpropsがあればpropsを代入する。
        const props = route.props || {};
        Object.assign(props, this.props);
        // Initialページ用にpropsをマージする
        Object.assign(props, route._props);
        // 現在のnavigatorオブジェクトをprops.navigatorに代入する。
        props.navigator = navigator;
        // keyが無いとReactに怒られる為、routeオブジェクトに代入したtitleを一意の値として渡す。
        props.key = route.title;
        // createElementで仮想DOMを作成する。
        return React.createElement(route.component, props);
    }

    render() {
        console.log('SafetyConfirmationPageNavigator::render()')
        return (
            <Navigator
                renderPage={this.renderPage}
                // 初期ページのInitial値を代入
                initialRoute={
                    {
                        component: SafetyConfirmationAggrigationPage,
                        title: 'FirstPage',
                        _props: this.props
                    }
                }
            />
        );
    }
}