import { resolve } from "url";
// import { headersToString } from "selenium-webdriver/http";
import { threadId } from "worker_threads";
import { isRegExp } from "util";
import { string } from "prop-types";
import { StringifyOptions } from "querystring";

// @DEVELOPMENT
// const endpoint: string = 'http://localhost/api/v1'

// // @RELEASE
const endpoint: string|undefined = process.env.REACT_APP_API_ENDPOINT;

interface Division {
    user_id: string
    division_id: string
    division_name: string
}

export class UserInfo {
    user_id: string = ""
    first_name: string = ""
    first_name_kana: string = ""
    last_name: string = ""
    last_name_kana: string = ""
    email_address: string = ""
    phone_number: string = ""
    permission_level: number = 0
    divisions: Division[] = []

    public static FromJson = (json: any): UserInfo => {
        let obj = new UserInfo()
        obj.user_id = json.user_id
        obj.first_name = json.first_name
        obj.first_name_kana = json.first_name_kana
        obj.last_name = json.last_name
        obj.last_name_kana = json.last_name_kana
        obj.email_address = json.email_address
        obj.phone_number = json.phone_number
        obj.permission_level = json.permission_level
        for (var i in json.divisions) {
            obj.divisions.push(json.divisions[i])
        }
        return obj
    }
}



interface State {
    user?: UserInfo
    is_loading?: boolean
}

class Tokens {
    access_token: string | null = null
    refresh_token: string | null = null
    public clear(){
        this.access_token = null;
        this.refresh_token = null;
    }
}

async function getResponseBody(response: Response) {
    const _json = await response.json().then(json => { return json });
    return _json
}

export class APIs {
    private static instance: APIs | null = null;
    private handle401LoginRequired: (() => void) | null = null;

    static getInstance(): APIs {
        // console.log('APIs.getInstance');
        if (!this.instance) {
            // console.log('call constructor');
            this.instance = new APIs();
        }
        // console.log("[DEBUG] A_Token:" + this.instance.tokens.access_token);
        return this.instance;
    }

    private tokens: Tokens = new Tokens()

    public constructor() {

        if ('tokens' in localStorage) {
            console.log('localstorage have tokens')
            // this._isLoggedIn = true;
            let _jsonstr: string | null;
            let _json: any;
            if ((_jsonstr = localStorage.getItem('tokens')) != null) {
                _json = JSON.parse(_jsonstr);
                this.tokens.access_token = _json['access_token']
                this.tokens.refresh_token = _json['refresh_token']

                // this.resolveMyInfo();
            }
        }
    }

    public setHandle401LoginRequired = (func: (() => void)) => {
        this.handle401LoginRequired = func;
    }

    public tryCheckToken = (successCallback: any, failureCallback: any) => {

        if (this.tokens.access_token != null) {
            // check token
            const url = endpoint + '/myinfo';
            this._fetch_only_precheck(url, 'GET')
                .then(json => {
                    successCallback();
                })
                .catch(err => {
                    if (err.message === 'LOGIN REQUIRED') {
                        failureCallback();
                    } else {
                        alert('error: ' + err);
                    }
                })
        } else {
            failureCallback()
        }

    }

    // public onReady = (func: any) => {
    //     if (this.ready) {
    //         func();
    //     } else {
    //         this._functionListsToExec.push(func);
    //     }
    // }

    private get accessHeader(): Headers {
        let header = new Headers()
        if (this.tokens.access_token != null) {
            header.append('x-marstoken', this.tokens.access_token);
        }
        header.append('Connection', "keep-alive");

        return header;
    }
    private get accessHeaderCheckOnly(): Headers {
        let header = new Headers()
        if (this.tokens.access_token != null) {
            header.append('x-marstoken', this.tokens.access_token);
        }
        header.append('Connection', "keep-alive");
        header.append('X-Token-Check-Only', 'true');

        return header;
    }
    private get headerWithoutToken(): Headers {
        let header = new Headers()
        header.append('Connection', "keep-alive");

        return header;
    }

    public Login(user_id: string, password: string, success_callback: any, failed_callback: any): void {
        let hdrs = new Headers();
        hdrs.append('Connection', "keep-alive");
        hdrs.append('x-mars-id', user_id);
        hdrs.append('x-mars-password', password);
        fetch(endpoint + '/login', {
            method: "GET",
            credentials: "omit",
            mode: "cors",
            headers: hdrs
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(function (err) {
                        throw Error(err.detail);
                    });
                }
                return response.json();
            })
            .then(json_ => {
                if (json_.user_id != user_id) {
                    throw Error('json dont have user_id');
                }
                // this._isLoggedIn = true;
                this.tokens.access_token = json_.access_token;
                this.tokens.refresh_token = json_.refresh_token;
                localStorage.setItem('tokens', JSON.stringify(json_));
                // var val = async () => await this.resolve_my_info();
                // this.resolve_my_info()
                console.log('Login resolved.')
                console.log(json_);
                // this.resolveMyInfo();
                success_callback();
            })
            .catch(err => {
                // this._isLoggedIn = false;
                console.log(err);
                failed_callback(err)
                return false;
            })
        // return true;
    }

    public ClearSession(successCallback: any, failedCallback: any){
        try{
            this.tokens.clear();
            localStorage.removeItem('tokens');

            //[TODO] Clear token 
        }catch{
            failedCallback();
            return
        }
        if(this.handle401LoginRequired !== null){
            this.handle401LoginRequired();
        }
        successCallback();
        return
        //[TODO] Do more stuff
    }

    private refreshToken() {
        let hdrs = new Headers();
        hdrs.append('Connection', "keep-alive");
        hdrs.append('x-marstoken', this.tokens.access_token || '');
        hdrs.append('x-marsrefreshtoken', this.tokens.refresh_token || '');

        console.log('starting refresh access token');
        const prom = fetch(endpoint + '/refreshtoken', {
            method: "GET",
            credentials: "omit",
            mode: "cors",
            headers: hdrs
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(json_ => {
                this.tokens.access_token = json_.access_token;
                this.tokens.refresh_token = json_.refresh_token;
                localStorage.setItem('tokens', JSON.stringify(json_));
                console.log('access token refreshed. ' + this.tokens.access_token);
                return json_;
            })
        return prom;
    }

    // public onMyInfoResolved = (func: any) => {
    //     if (this.ready) {
    //         func(this.myInfo)
    //     } else {
    //         this.resolveMyInfo()
    //             .then(info => {
    //                 this._myInfo = UserInfo.FromJson(info);
    //                 return info;
    //             })
    //             .then(info => func(info))
    //             .then(_ => this._ready = true)
    //             .then(() => {
    //                 while (this._functionListsToExec.length != 0) {
    //                     let func: any = this._functionListsToExec.shift();
    //                     func();
    //                 }
    //             })
    //     }
    // }

    // private resolveMyInfo = () => {
    //     return (
    //         this.getMyInfo()
    //             .then(_json => UserInfo.FromJson(_json))
    //     );
    // }

    public getMyInfo = (): Promise<any> => {
        return (
            this._fetch(endpoint + '/myinfo', 'GET', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )

    }

    // private _callAPI_GET = (url: string, headers: Headers = this.accessHeader): Promise<Response> => {
    //     return (
    //         fetch(url, {
    //             method: 'GET',
    //             credentials: 'omit',
    //             mode: 'cors',
    //             headers: headers
    //         })
    //     )
    // }

    private _fetch = (url: string, method: string, headers: Headers, body: string | null = null): Promise<Response> => {
        headers.append('access-control-expose-headers', 'x-error')
        headers.append('access-control-allow-headers', 'x-error')
        return (
            fetch(url, {
                method: method,
                credentials: 'omit',
                mode: 'cors',
                headers: headers,
                body: body
            })
                .then(response => {
                    // const status_code = response.status;
                    // const body = getResponseBody(response);

                    if (response.ok) {
                        console.log('response is ok');
                        return response;
                    }
                    if (response.status === 401) {
                        if (this.handle401LoginRequired != null) {
                            this.handle401LoginRequired();
                        }
                        throw Error('LOGIN REQUIRED');
                    }
                    if (response.status === 403) {
                        return response.json().then(json_ => {
                            throw Error('PERMISSION DENIED:' + json_.detail)
                        })
                        // throw Error('PERMISSION DENIED:'+response.json().then);
                    }
                    if (response.status === 404) {
                        throw Error('RESOURCE NOT FOUND');
                    }
                    if (response.status === 450) {
                        // refresh token
                        console.log('refresh token');
                        console.log(response);

                        let p = this.refreshToken()
                            .then(json_ => {
                                headers.set('x-marstoken', this.tokens.access_token || '');
                                console.log('re-fetching');
                                return this._fetch(url, method, headers, body);
                            })
                        return p;
                    }
                    throw Error('Unknown response error: ' + response.status)
                })
        );
    }

    private _fetch_only_precheck = (url: string, method: string): Promise<void> => {
        return (
            this._fetch(
                url,
                method,
                this.accessHeaderCheckOnly
            )
                .then(response => {
                    if (response.ok) {
                        console.log('precheck response is ok');
                        return response.json();
                    }
                    if (response.status == 401) {
                        console.log('LOGIN REQUIRED');
                        throw Error('LOGIN REQUIRED')
                    }
                    if (response.status == 403) {
                        throw Error('PERMISSION DENIED')
                    }
                    throw Error('Unknown response error: ' + response.status)
                })
                .then(json => {
                    if (json.status == 'OK') {
                        console.log('precheck json.status is ok');
                        return json;
                    }
                    throw Error('Server error.' + JSON.stringify(json));
                })
        )
    }

    private _fetch_with_precheck = (url: string, method: string): Promise<Response> => {

        return (
            this._fetch_only_precheck(url, method)
                .then(() => {
                    console.log('fetching non-check call');
                    return this._fetch(url, method, this.accessHeader);
                })
        )
    }

    public startSCSequence_checkPermission = () => {
        let url = endpoint + '/scsequence?title=permissionCheck&comment=permissionCheck';
        return this._fetch_only_precheck(url, 'POST');
    }

    public startSCSequence = (title: string, comment: string, participants: string) => {
        let url = endpoint + '/scsequence';
        url += '?title=' + title;
        url += '&comment=' + comment;
        url += '&participant_user_ids=' + participants

        return (
            this._fetch_with_precheck(url, 'POST')
                .then(response => {
                    return response.json()
                })
        )
    }

    public closeSCSequence_checkPermission = () => {
        let url = endpoint + '/scsequence?sequence_id=-1'
        return this._fetch_only_precheck(url, 'DELETE')
    }

    public closeSCSequence = (sequence_id: number) => {
        let url = endpoint + '/scsequence?sequence_id=' + sequence_id.toString();
        return (
            this._fetch_with_precheck(url, 'DELETE')
                .then(response => {
                    return response.json()
                })
        )
    }

    public getSCSequenceInfo = (status: string) => {
        let url = endpoint + '/scsequence?status=' + status;
        return (
            this._fetch(url, 'GET', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }

    public getThreads = (sequence_id: number) => {
        let url = endpoint + '/threads/' + sequence_id.toString();
        return (
            this._fetch(url, 'GET', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }

    public getThreadSummaries = (sequence_id: number) => {
        let url = endpoint + '/threadsummaries/' + sequence_id.toString();
        return (
            this._fetch(url, 'GET', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }

    public getThreadSummary = (thread_id: number) => {
        let url = endpoint + '/threadsummary/' + thread_id.toString();
        return (
            this._fetch(url, 'GET', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }

    public getThread = (thread_id: number) => {
        let url = endpoint + '/thread/' + thread_id.toString();
        return (
            this._fetch(url, 'GET', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }

    public getDivisions = () => {
        let url = endpoint + '/divisions'
        return (
            this._fetch(url, 'GET', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }

    public postMessage = (threadId: number, response: any) => {
        let url = endpoint + '/message/' + threadId.toString();
        return (
            this._fetch(url, 'POST', this.accessHeader, JSON.stringify(response))
                .then(response => {
                    return response.json()
                })
        )
    }

    public flipThreadStatus = (threadId: number) => {
        let url = endpoint + '/thread/' + threadId.toString();
        return (
            this._fetch(url, 'DELETE', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }

    public getAggrigationData = (sequenceId: number) => {
        let url = endpoint + '/aggrigation/' + sequenceId.toString()
        return (
            this._fetch(url, 'GET', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }
    public getUsers = (users: string[]) => {
        let strUsers: string
        if (users.length == 0) {
            strUsers = ','
        } else {
            strUsers = users.join(',')
        }
        let url = endpoint + '/users/' + strUsers;
        return (
            this._fetch(url, 'GET', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }
    public getUnreadResponseByThreadId = (threadId: number) => {
        let url = endpoint + '/unread-response/' + threadId.toString()
        return (
            this._fetch(url, 'GET', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }
    public setUnreadResponseByThread = (threadId: number) => {
        let url = endpoint + '/unread-response/' + threadId.toString();
        return (
            this._fetch(url, 'PUT', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }
    public testNotification = () => {
        let url = endpoint + '/notifytest';
        return (
            this._fetch(url, 'PUT', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }
    public getNotificationLog = (start: string, end: string, limit?: string) => {
        let url = endpoint + '/notification-log'

        let query = []
        if (start !== undefined && start !== '') {
            query.push('start=' + start)
        }
        if (end !== undefined && end !== '') {
            query.push('end=' + end)
        }
        if (limit !== undefined && limit !== '') {
            query.push('&limit=' + limit)
        }

        if (query.length != 0) {
            url += '?' + query.join('&')
        }

        return (
            this._fetch(encodeURI(url), 'GET', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }
    public deleteUserTokens = (userId: string) => {
        let url = endpoint + '/tokens/' + userId;
        return (
            this._fetch(url, 'DELETE', this.accessHeader)
                .then(response => {
                    return response.json()
                })
        )
    }

}

/**
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 * @return  url {url} 対象のURL文字列（任意）
 */
export function getParam(name: string, url?: string | undefined) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function formatdate(date: Date, format: string) {
    format = format.replace(/YYYY/, date.getFullYear().toString());
    format = format.replace(/MM/, (date.getMonth() + 1).toString());
    format = format.replace(/DD/, date.getDate().toString());
    format = format.replace(/HH/, date.getHours().toString());
    format = format.replace(/mm/, ('00' + date.getMinutes()).slice(-2));
    format = format.replace(/SS/, date.getSeconds().toString());

    return format;
}

export function UTC2Local(time: string): string {
    var time_ = Date.parse(time + '+00:00');
    var dt = new Date(time_);
    var now = new Date();
    if (dt.getFullYear() !== now.getFullYear()) {
        // u\00A0 => &nbsp;
        return formatdate(dt, 'YYYY/MM/DD\u00A0HH:mm');
    }
    else if (dt.toLocaleDateString() !== now.toLocaleDateString()) {
        // u\00A0 => &nbsp;
        return formatdate(dt, 'MM/DD\u00A0HH:mm');
    } else {
        return formatdate(dt, 'HH:mm');
    }
}

export function UTC2Local2(time: string): string | null {

    if (time === null || typeof time === 'undefined') {
        return null
    }

    var time_ = Date.parse(time + '+00:00');
    return formatdate((new Date(time_)), 'YYYY/MM/DD\u00A0HH:mm:SS')
}

export function shrinkString(string_: string, length: number): string {
    if (string_.length <= length) {
        return string_;
    } else {
        return string_.substr(0, length) + '...';
    }

}