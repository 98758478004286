import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, Icon, Page, Toolbar, BackButton, Button, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../icon/right-arrow1.svg';
import '../App.css';
import { UserLoginStatus, AppCallbackType, AppController } from '../App'
import { SequenceSelecter } from './SubComponents/SequenceSelecter'
import { APIs, getParam } from '../model/MarsAPIs'
import { jsxAttribute } from '@babel/types';
import { ThreadList, ThreadViewer } from './ThreadList';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import '../css/mars.css'


const POLLING_INTERVAL = 15000;

class SafetyConfirmationPage extends React.Component<any, any>{
    private pollingIntervalId: NodeJS.Timer | null = null;
    private callbackWhenComponentDidUpdate: any|null;
    private callbacksWhenPageActivated: { [key: string]: any } = {};
    constructor(p: any, s: any) {
        super(p);

        // let scidparam = getParam('scid');

        this.state = {
            // selectedIndex: '"-1"',
            // activeSequences: [],
            selectedSequenceId: null,
            selectedSequenceInfo: null,
            selectedSequenceThreadSummaries: []
        }

        AppController.getInstance().registerAppCallback(
            AppCallbackType.PageActivated, 
            this.props.index, 
            this.onPageActivate
        );
        AppController.getInstance().registerAppCallback(
            AppCallbackType.PageDeactivated, 
            this.props.index, 
            this.onPageDeactivate
        )
        
        this.registerPollingUpdate();
        // this.updateActiveSequences();
        // this.props.registerAppCallback(AppCallbackType.PageDeactivated, this.props.index, this.onPageDeactivate);
    }

    componentWillMount = () => {
        this.props.registerToolbarRenderer(this.props.index, () => {
            // console.log('Toolbar renderer found ' + this.state.activeSequences.length + ' items')
            return (
                <Toolbar key='__SafetyConfirmation__Default__'>
                    <div></div>
                    <div className="center">安否確認</div>
                    <div className='right' key='__SafetyConfirmation__'><UserLoginStatus></UserLoginStatus></div>
                </Toolbar>
            );
        })
    }
    componentDidUpdate = () => {
        if(this.callbackWhenComponentDidUpdate != null){
            this.callbackWhenComponentDidUpdate();
        }
    }
    registerCallbackWhenComponentDidUpdate = (func: any) => {
        this.callbackWhenComponentDidUpdate = func;
    }
    unregisterCallbackWhenComponentDidUpdate = () => {
        this.callbackWhenComponentDidUpdate = null;
    }

    registerPollingUpdate = () => {
        if (this.pollingIntervalId != null) {
            this.unregisterPollingUpdate();
        }
        let intervalId = setInterval(()=>{
            this.updateThreadSummaries(this.state.selectedSequenceId)
        }, POLLING_INTERVAL);
        this.pollingIntervalId = intervalId;
    }
    unregisterPollingUpdate = () => {
        if (this.pollingIntervalId != null) {
            clearInterval(this.pollingIntervalId)
            this.pollingIntervalId = null;
        }
    }
    
    onPageActivate = () => {
        console.log('Safety confirmation Page activated');
        // this.updateActiveSequences();
        this.updateThreadSummaries(this.state.selectedSequenceId);
        for (const key in this.callbacksWhenPageActivated) {
            this.callbacksWhenPageActivated[key]();
        }
        this.registerPollingUpdate();
    }
    onPageDeactivate = () => {
        console.log('Safety confirmation Page deactivated')
        this.unregisterPollingUpdate();
        // this.props.setToolbarRenderer(null);
    }

    updateThreadSummaries = (sequence_id?: number) => {
        if (this.state.selectedSequenceId === null) {
            this.setState({
                selectedSequenceThreadSummaries: []
            })
            return
        }
        let sequence_id_:number = -1;
        if (sequence_id === undefined) {
            sequence_id_ = this.state.selectedSequenceId;
        }else{
            sequence_id_ = sequence_id;
        }

        APIs.getInstance().getThreadSummaries(sequence_id_)
            .then(json => {
                this.setState({
                    selectedSequenceThreadSummaries: json
                })
                console.log('[DEBUG]ThreadSummaries')
                console.log(this.state.selectedSequenceThreadSummaries)
            })
            .catch(err => {
                this.props.notify(err);
                this.setState({
                    selectedSequenceThreadSummaries: []
                })
            })
    }

    onThreadListItemClick = (thread_summary: any, updatePrevThreadUnreadResponse:any) => {
        // this.props.notify(thread_id);
        this.props.navigator.pushPage({
            component: ThreadViewer,
            title: 'ThreadViewer',
            _props: Object.assign(
                {
                    thread_summary: thread_summary,
                    updateThreads: this.updateThreadSummaries,
                    updatePrevThreadUnreadResponse: updatePrevThreadUnreadResponse,
                    registerCallbackWhenComponentDidUpdate:this.registerCallbackWhenComponentDidUpdate,
                    unregisterCallbackWhenComponentDidUpdate:this.unregisterCallbackWhenComponentDidUpdate
                },
                this.props
            )
        });
    }

    registerCallbackForUpdate = (key: string, func: any) => {
        this.callbacksWhenPageActivated[key] = func
    }

    onSequenceSelected = (sequenceId:number) => {
        this.setState({
            selectedSequenceId:sequenceId
        }, this.updateThreadSummaries)
    }

    render() {
        let sequenceComment: string = '';
        if (this.state.selectedSequenceInfo != null) {
            sequenceComment = this.state.selectedSequenceInfo.comment
        }
        return (
            <Page>
                <div style={{ width: '100%', position: 'absolute' }}>
                    {/* <div style={{ width: '100%', zIndex: 999 }}> */}
                        {/* <div style={{ width: '100%', height: '52px', position: 'fixed', zIndex: 999, backgroundColor: '#efeff4' }}> */}
                        <div style={{ width: '100%', zIndex: 999, backgroundColor: '#efeff4' }}>
                            <SequenceSelecter
                                onSequenceSelected={this.onSequenceSelected}
                                registerCallbackForUpdate={this.registerCallbackForUpdate}
                            />
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <div style={{ fontSize: '0.8em' }}>
                                    {sequenceComment}
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                    <div style={{ width: '100%', position: 'static', marginTop: '0px' }}>
                        <ThreadList
                            threadSummaries={this.state.selectedSequenceThreadSummaries}
                            notify={this.props.notifyMessage}
                            onListItemClick={this.onThreadListItemClick}
                        >
                        </ThreadList>
                    </div>
                </div>
            </Page>
        )
    }
}

export default class SafetyConfirmationPageNavigator extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        // console.log('SafetyConfirmationPageNavigator::constructor');
        // console.log(props);
    }

    // renderPageのPropsに与える関数
    // ・現在のrouteの情報(route)とnavigatorオブジェクトを引数にとる
    // ・navigator.pushPage(route)される度にrenderPageが実行される。
    renderPage(route: any, navigator: any) {
        console.log('SafetyConfirmationPageNavigator::renderPage()')
        // console.log(route.component)
        // console.log(this.props)
        // console.log(route.props)
        // console.log(route)
        // 現在のページのpropsがあればpropsを代入する。
        const props = route.props || {};
        Object.assign(props, this.props);
        // Initialページ用にpropsをマージする
        Object.assign(props, route._props);
        // 現在のnavigatorオブジェクトをprops.navigatorに代入する。
        props.navigator = navigator;
        // keyが無いとReactに怒られる為、routeオブジェクトに代入したtitleを一意の値として渡す。
        props.key = route.title;
        // createElementで仮想DOMを作成する。
        return React.createElement(route.component, props);
    }

    render() {
        console.log('SafetyConfirmationPageNavigator::render()')
        return (
            <Navigator
                renderPage={this.renderPage}
                // 初期ページのInitial値を代入
                initialRoute={
                    {
                        component: SafetyConfirmationPage,
                        title: 'FirstPage',
                        _props: this.props
                    }
                }
            />
        );
    }
}