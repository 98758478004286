import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, ProgressCircular, Modal, Page, Toolbar, BackButton, Button, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../icon/right-arrow1.svg';
import '../../App.css';
import { DetailPageBase } from './PageBase'
import { APIs } from '../../model/MarsAPIs'
import { jsxAttribute } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

export class StartSafetyConfirmationSequencePage extends DetailPageBase {
    public PageTitle: string = '安否確認開始'
    constructor(p: any, s: any) {
        super(p, s);
        Object.assign(
            this.state,
            {
                title: new Date().toLocaleString() + ' 安否確認',
                comment: '災害が発生したため、安否確認を実施します',
                loading: false,
            }
        )
    }
    onStart = () => {
        let title = this.state.title
        let comment = this.state.comment
        this.setState({ loading: true }, () => {
            APIs.getInstance().startSCSequence(title, comment, 'all')
                .then(json => {
                    if (json.status != 'OK') {
                        throw Error('ResponseErro' + JSON.stringify(json))
                    }
                    //成功
                    this.setState({ loading: false })
                    this.props.notify(
                        '安否確認シーケンス #' + json.sequence_id + ' が開始されました--' + title
                    )
                })
                .catch(e => {
                    this.props.notify('error!' + e);
                })
        })
        // this.setState({loading:false})
    }
    render() {
        let loading: JSX.Element;
        if (this.state.loading) {
            loading = <ProgressCircular indeterminate />
        } else {
            loading = <div></div>
        }
        return (
            <Page>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Modal isOpen={this.state.loading}>
                        <ProgressCircular indeterminate />
                    </Modal>
                    <div style={{ width: '90%', marginTop: '1em' }}>
                        <div style={{ fontSize: '1.2em' }}>
                            安否確認を開始します
                        </div>
                        <div style={{ marginLeft: '0.5em' }}>
                            <div>
                                <Input
                                    id='title'
                                    type='text'
                                    onChange={(e) => this.setState({ title: e.target.value })}
                                    value={this.state.title}
                                    float
                                    modifier='material'
                                    style={{ width: '95%', marginTop: '1.5em' }}
                                    placeholder='タイトル'
                                >
                                </Input>
                            </div>
                            <div>
                                <div style={{ fontSize: "0.7em", marginTop: '1em', color: '#3d5afe' }}>コメント</div>
                                <textarea
                                    id='comment'
                                    // type='text' 
                                    onChange={(e) => this.setState({ comment: e.target.value })}
                                    value={this.state.comment}
                                    // modifier='material'
                                    // placeholder='コメント'
                                    // float
                                    className='text-input--material'
                                    style={{ width: '95%', height: '5em', border: 'solid', borderWidth: '1px' }}
                                >
                                </textarea>
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={this.onStart} style={{ marginTop: '2em' }}>安否確認を開始する</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}