import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, ProgressCircular, Modal, Page, Toolbar, BackButton, Button, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../icon/right-arrow1.svg';
import '../App.css';
import { UserLoginStatus, AppController } from '../App'
import { StartSafetyConfirmationSequencePage } from './SubComponents/StartSafetyConfirmationSequencePage'
import { EndSafetyConfirmationSequencePage } from './SubComponents/EndSafetyConfirmationSequencePage'
import { EarthQuakeHistoryPage } from './SubComponents/EarthQuakeHistoryPage'
import { SettingPage } from './SubComponents/SettingPage'
import { UserAccountInfoPage } from './SubComponents/UserAccountInfoPage'
import { SystemManagementPage } from './SubComponents/SystemManagementPage'
import { HelpPage } from './SubComponents/HelpPage'
import { LogoutPage } from './SubComponents/LogoutPage'
import { APIs } from '../model/MarsAPIs'
import { jsxAttribute } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

interface Division {
    user_id: string
    division_id: string
    division_name: string
}

interface UserInfo {
    user_id: string
    first_name: string
    first_name_kana: string
    last_name: string
    last_name_kana: string
    email_address: string
    phone_number: string
    permission_level: number
    divisions: Division[]
}

interface State {
    user?: UserInfo
    is_loading?: boolean
}

// class StartSafetyConfirmationSequencePage extends DetailPageBase {
//     public PageTitle: string = '安否確認開始'
//     constructor(p: any, s: any) {
//         super(p, s);
//         Object.assign(
//             this.state,
//             {
//                 title: new Date().toLocaleString() + ' 安否確認',
//                 comment: '災害が発生したため、安否確認を実施します',
//                 loading: false,
//             }
//         )
//     }
//     onStart = () => {
//         let title = this.state.title
//         let comment = this.state.comment
//         this.setState({ loading: true }, () => {
//             APIs.getInstance().startSCSequence(title, comment, 'all')
//                 .then(json => {
//                     if (json.status != 'OK') {
//                         throw Error('ResponseErro' + JSON.stringify(json))
//                     }
//                     //成功
//                     this.setState({ loading: false })
//                     this.props.notify(
//                         '安否確認シーケンス #' + json.sequence_id + ' が開始されました--' + title
//                     )
//                 })
//                 .catch(e => {
//                     this.props.notify('error!' + e);
//                 })
//         })
//         // this.setState({loading:false})
//     }
//     render() {
//         let loading: JSX.Element;
//         if (this.state.loading) {
//             loading = <ProgressCircular indeterminate />
//         } else {
//             loading = <div></div>
//         }
//         return (
//             <Page>
//                 <div style={{ display: 'flex', justifyContent: 'center' }}>
//                     <Modal isOpen={this.state.loading}>
//                         <ProgressCircular indeterminate />
//                     </Modal>
//                     <div style={{ width: '90%', marginTop: '1em' }}>
//                         <div style={{ fontSize: '1.2em' }}>
//                             安否確認を開始します
//                         </div>
//                         <div style={{ marginLeft: '0.5em' }}>
//                             <div>
//                                 <Input
//                                     id='title'
//                                     type='text'
//                                     onChange={(e) => this.setState({ title: e.target.value })}
//                                     value={this.state.title}
//                                     float
//                                     modifier='material'
//                                     style={{ width: '95%', marginTop: '1.5em' }}
//                                     placeholder='タイトル'
//                                 >
//                                 </Input>
//                             </div>
//                             <div>
//                                 <div style={{ fontSize: "0.7em", marginTop: '1em', color: '#3d5afe' }}>コメント</div>
//                                 <textarea
//                                     id='comment'
//                                     // type='text' 
//                                     onChange={(e) => this.setState({ comment: e.target.value })}
//                                     value={this.state.comment}
//                                     // modifier='material'
//                                     // placeholder='コメント'
//                                     // float
//                                     className='text-input--material'
//                                     style={{ width: '95%', height: '5em', border: 'solid', borderWidth: '1px' }}
//                                 >
//                                 </textarea>
//                             </div>
//                         </div>
//                         <div>
//                             <div style={{ display: 'flex', justifyContent: 'center' }}>
//                                 <Button onClick={this.onStart} style={{ marginTop: '2em' }}>安否確認を開始する</Button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </Page>
//         );
//     }
// }
// class EndSafetyConfirmationSequencePage extends DetailPageBase {
//     public PageTitle: string = '安否確認終了'
//     constructor(p: any, s: any) {
//         super(p, s);
//         Object.assign(
//             this.state,
//             {
//                 selected_value: null, // AvailableなもののIndex
//                 selected_sequence_id: null,
//                 availableSCs: [],
//                 comment: "",
//                 created_by_name: "",
//                 created_at: ""
//             }
//         )
//         this.update();
//     }

//     updateSelectState = (value: String) => {
//         // let val:String = ev.target.value;
//         let val_n = Number(value.replace(/"/g, ''))
//         this.setState({
//             selected_value: value,
//             selected_sequence_id: this.state.availableSCs[val_n].sequence_id,
//             comment: this.state.availableSCs[val_n].comment,
//             created_by_name: this.state.availableSCs[val_n].created_by_name,
//             created_at: this.state.availableSCs[val_n].created_at,
//             closeButtonDisabled: false
//         })
//     }
//     clearSelectState = () => {
//         this.setState({
//             selected_value: null,
//             selected_sequence_id: null,
//             comment: null,
//             created_by_name: null,
//             created_at: null,
//             closeButtonDisabled: true
//         })
//     }

//     update = () => {
//         APIs.getInstance().getSCSequenceInfo('open')
//             .then(json => {
//                 console.log('Loaded sequences');
//                 // console.log(json)
//                 this.setState({
//                     availableSCs: json
//                 })
//                 console.log('json length')
//                 console.log(json.length)
//                 if (this.state.availableSCs.length == 0) {
//                     this.clearSelectState();
//                 } else {
//                     this.updateSelectState('"0"');
//                 }
//             })
//             .catch(err => {
//                 this.props.notify('安否確認シーケンス情報の取得に失敗しました' + err.toString())
//             })
//     }

//     onSelectSelected = (ev: any) => {
//         this.updateSelectState(ev.target.value);
//     }

//     onCloseSCButtonClick = () => {
//         APIs.getInstance().closeSCSequence(this.state.selected_sequence_id)
//             .then(json => {
//                 if (json.status == 'OK') {
//                     this.props.notify('安否登録シーケンス #' + json.sequence_id + ' をクローズしました');
//                     this.update();
//                 } else {
//                     throw Error('Error!' + JSON.stringify(json));
//                 }
//             })
//             .catch(err => {
//                 this.props.notify(err.toString());
//             });
//     }

//     renderSelect = () => {
//         // console.log('[DEBUG]renderSelect: ' + this.state.availableSCs);
//         if (this.state.availableSCs.length == 0) {
//             return (
//                 <div style={{ display: 'flex', justifyContent: 'center' }}>
//                     <div style={{ fontSize: '1.1em' }}>現在オープン中のシーケンスはありません</div>
//                 </div>
//             )
//         } else {
//             return (
//                 <Select style={{ width: '100%', fontSize: '16px' }}
//                     modifier="material"
//                     value={this.state.selected_value}
//                     onChange={this.onSelectSelected}>
//                     {
//                         this.state.availableSCs.map((sc: any, index: number) => {
//                             return (
//                                 <option
//                                     value={'"' + index + '"'}
//                                     key={sc.sequence_id.toString()}
//                                 >
//                                     {'#' + sc.sequence_id + ' ' + sc.title}
//                                 </option>
//                             )
//                         })
//                     }
//                 </Select>
//             )
//         }
//     }

//     render() {
//         return (
//             <Page>
//                 <div style={{ display: 'flex', justifyContent: 'center' }}>
//                     <div style={{ width: '90%', marginTop: '1em' }}>
//                         <div style={{ fontSize: '1.2em' }}>
//                             安否確認シーケンスを終了します
//                         </div>
//                         <div style={{ fontSize: '0.7em' }}>
//                             安否確認シーケンスをクローズすることで当該の安否確認シーケンスに対して誰もアクション出来なくなります
//                         </div>

//                         <div style={{ marginTop: '1.5em' }}>
//                             {this.renderSelect()}
//                         </div>

//                         <div style={{ fontSize: '0.8em', marginTop: '1em', marginLeft: '1.5em' }}>
//                             <table style={{ width: '100%' }}>
//                                 <tbody>
//                                     <tr>
//                                         <td style={{ color: '#999' }}>
//                                             開始日時
//                                         </td>
//                                         <td>
//                                             {this.state.created_at}
//                                         </td>
//                                     </tr>
//                                     <tr>
//                                         <td style={{ color: '#999' }}>
//                                             起票者
//                                         </td>
//                                         <td>
//                                             {this.state.created_by_name}
//                                         </td>
//                                     </tr>
//                                     <tr>
//                                         <td style={{ width: '5em', verticalAlign: 'top', color: '#999' }}>
//                                             コメント
//                                         </td>
//                                         <td>
//                                             {this.state.comment}
//                                         </td>
//                                     </tr>
//                                 </tbody>
//                             </table>
//                         </div>

//                         <div style={{ display: 'flex', justifyContent: 'center' }}>
//                             <div style={{ marginTop: '3em' }}>
//                                 <Button
//                                     modifier='material'
//                                     onClick={this.onCloseSCButtonClick}
//                                     disabled={this.state.closeButtonDisabled}
//                                 >
//                                     安否確認シーケンスをクローズ
//                                 </Button>
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </Page>
//         )
//     }
// }

// class HelpPage extends DetailPageBase {
//     public PageTitle: string = 'ヘルプページ'
//     render() {
//         return (
//             <Page>
//                 <h1>
//                     ヘルプページ
//                 </h1>
//                 <div>全ての画像は開発版です。</div>
//                 <hr></hr>
//                 <div>
//                     <a
//                         href='https://www.geotechnos-internal.net/online-guide/case/'
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         利用者ガイド
//                     </a>
//                 </div>
//                 <div>
//                     <a
//                         href=''
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         開発者ガイド
//                     </a>
//                 </div>

//             </Page>
//         )
//     }
// }

// class LoginAccountInfoPage extends DetailPageBase{
//     render() {
//         return(
//             <Page><div>LoginAccountInfoPage</div></Page>

//         )
//     }
// }

class OtherPage extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        // this.pushDetailPage = this.pushDetailPage.bind(this);
        console.log('OtherPage::constructor');
        console.log(props);
    }
    pushStartSafetyConfirmationPage = () => {
        console.log('OtherPage::pushSafetyConfirmationPage')
        // Check permission
        APIs.getInstance().startSCSequence_checkPermission()
            .then(() => {
                // propsに格納されているnavigatorオブジェクトのpushPageで遷移したいページの情報を渡す。
                this.props.navigator.pushPage({
                    component: StartSafetyConfirmationSequencePage,
                    title: 'StartSafetyConfirmationSequencePage'
                });
            })
            .catch(err => {
                console.log(err)
                this.props.notify(err.toString())
            })
    }
    pushCloseSafetyConfirmationPage = () => {
        console.log('OtherPage::pushEndConfirmationPage')
        // Check permission
        APIs.getInstance().closeSCSequence_checkPermission()
            .then(() => {
                // propsに格納されているnavigatorオブジェクトのpushPageで遷移したいページの情報を渡す。
                this.props.navigator.pushPage({
                    component: EndSafetyConfirmationSequencePage,
                    title: 'EndSafetyConfirmationSequencePage'
                });
            })
            .catch(err => {
                this.props.notify(err.toString())
            })
    }
    pushEarthQuakeHistoryPage = () => {
        this.props.navigator.pushPage({
            component: EarthQuakeHistoryPage,
            title: 'EarthQuakeHistoryPage'
        })
    }
    pushSettingPage = () => {
        this.props.navigator.pushPage({
            component: SettingPage,
            title: 'SettingPage'
        })
    }
    pushLoginAccountInfoPage = () => {
        this.props.navigator.pushPage({
            component: UserAccountInfoPage,
            title: 'LoginAccountInfoPage',
            _props: { user_id: AppController.getInstance().getLoginUserInfo().user_id }
        })
    }
    pushSystemManagementPage = () => {
        this.props.navigator.pushPage({
            component: SystemManagementPage,
            title: 'SystemManagementPage'
        })
    }
    pushHelpPage = () => {
        this.props.navigator.pushPage({
            component: HelpPage,
            title: 'HelpPage'
        });
    }
    pushLogoutPage = () => {
        this.props.navigator.pushPage({
            component: LogoutPage,
            title: 'logout'
        })
    }

    render() {
        return (
            <Page>
                <List>
                    <ListItem onClick={this.pushStartSafetyConfirmationPage}>
                        <div className='left'>安否確認を開始する</div>
                        {/* <Icon className='right' src>{icon_right_arrow}</Icon> */}
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                    <ListItem onClick={this.pushCloseSafetyConfirmationPage}>
                        <div className='left'>安否確認を終了する</div>
                        {/* <Icon className='right' src>{icon_right_arrow}</Icon> */}
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                    <ListItem onClick={this.pushEarthQuakeHistoryPage}>
                        <div className='left'>地震検知履歴</div>
                        {/* <Icon className='right' src>{icon_right_arrow}</Icon> */}
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                    <ListItem onClick={this.pushSettingPage}>
                        <div className='left'>設定</div>
                        {/* <Icon className='right' src>{icon_right_arrow}</Icon> */}
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                    <ListItem onClick={this.pushLoginAccountInfoPage}>
                        <div className='left'>ログインユーザー情報</div>
                        {/* <Icon className='right' src>{icon_right_arrow}</Icon> */}
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                    <ListItem onClick={this.pushSystemManagementPage}>
                        <div className='left'>システム管理</div>
                        {/* <Icon className='right' src>{icon_right_arrow}</Icon> */}
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                    <ListItem onClick={this.pushHelpPage}>
                        <div className='left'>ヘルプ</div>
                        {/* <Icon className='right' src>{icon_right_arrow}</Icon> */}
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                    <ListItem onClick={this.pushLogoutPage}>
                        <div className='left'>サインアウト</div>
                        {/* <Icon className='right' src>{icon_right_arrow}</Icon> */}
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                </List>
            </Page>
        )
    }
}

export default class OtherPageNavigator extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.renderPage = this.renderPage.bind(this);
        console.log('OtherPageNavigator::constructor');
        console.log(props);
    }

    // renderPageのPropsに与える関数
    // ・現在のrouteの情報(route)とnavigatorオブジェクトを引数にとる
    // ・navigator.pushPage(route)される度にrenderPageが実行される。
    renderPage(route: any, navigator: any) {
        console.log('OtherPageNavigator::renderPage()')
        // 現在のページのpropsがあればpropsを代入する。
        const props = route.props || {};
        Object.assign(props, this.props);
        // Initialページ用にpropsをマージする
        if (route._props) {
            Object.assign(props, route._props);
        }
        // 現在のnavigatorオブジェクトをprops.navigatorに代入する。
        props.navigator = navigator;
        // keyが無いとReactに怒られる為、routeオブジェクトに代入したtitleを一意の値として渡す。
        props.key = route.title;
        // createElementで仮想DOMを作成する。
        return React.createElement(route.component, props);
    }

    render() {
        console.log('OtherPageNavigator::render()')
        return (
            <Navigator
                renderPage={this.renderPage}
                // 初期ページのInitial値を代入
                initialRoute={
                    {
                        component: OtherPage,
                        title: 'FirstPage'
                    }
                }
            />
        );
    }
}