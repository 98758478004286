import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, Icon, AlertDialog, ProgressCircular, Page, Toolbar, BackButton, Button, Modal, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab, Switch } from "react-onsenui";
import TextareaAutosize from 'react-autosize-textarea';
import { Map, Marker, Popup, TileLayer, CircleMarker, Circle } from 'react-leaflet';
import logo from '../logo.svg';
import icon_right_arrow from '../icon/right-arrow1.svg';
import '../App.css';
import { UserLoginStatus, AppController } from '../App'
import { APIs, UserInfo, UTC2Local, shrinkString } from '../model/MarsAPIs'
import { PersonSafetyConst, MessageType, ThreadStatus, POLLING_INTERVAL_MILLIS } from '../model/constants'
import { UserAccountInfoPage } from './SubComponents/UserAccountInfoPage'
import { jsxAttribute } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import '../css/thread-list.css';
import '../css/checkbox.css'
import '../css/mars.css'
import '../css/mars-message-thread.css'
import telehphone_icon from '../icon/telephone.svg'
import "leaflet/dist/leaflet.css";
import mail_icon from '../icon/mail.svg'
import earth_quake_icon from '../icon/earth-quake.svg'

export class NavigatorChildBase extends React.Component<any, any>{
    private previousToolbarRenderer: any;
    public toolbarRenderer: () => void = () => {
        return (
            <Toolbar>
                <div className='left'><BackButton onClick={this.onBackButton}></BackButton></div>
                <div className='center'>
                    <img src={earth_quake_icon} className='earth-quake-icon-toolbar'></img>
                    {this.state.pageTitle}
                </div>
                <div className='right'><UserLoginStatus></UserLoginStatus></div>
            </Toolbar>
        );
    }
    constructor(p: any, s: any) {
        super(p);
        this.state = {
            pageTitle: ''
        }

        this.previousToolbarRenderer = AppController.getInstance().getCurrentToolbarRenderer();
        // this.props.registerToolbarRenderer(this.props.index , this.toolbarRenderer);
        // this.props.applyToolbarRendererNow();
    }

    public onBackButton() {
        // Running 中にpop()するとエラるのでガード
        if (!this.props.navigator.isRunning()) {
            this.props.navigator.popPage();
            AppController.getInstance().registerToolbarRenderer(this.props.index, this.previousToolbarRenderer);
            AppController.getInstance().applyToolbarRendererNow();
        }
    }

    render() {
        return (
            <Page>
                Please overload this method!!
            </Page>
        );
    }
}

class Checkboxes extends React.Component<any, any>{
    constructor(props: any) {
        super(props);

        let checked: { [key: number]: boolean } = []
        for (var key in this.props.data) {
            checked[Number(key)] = false;
        }
        this.state = { checked: checked }
    }
    onChange = (ev: any) => {
        let checked_ = this.state.checked;
        for (var key in this.props.data) {
            if (ev.target.value == key) {
                checked_[ev.target.value] = !this.state.checked[ev.target.value]
            } else {
                checked_[key] = false;
            }
        }
        this.setState({
            chacked: checked_
        })

        let checked_idx = -1;
        checked_.forEach((element: boolean, idx: number) => {
            if (element === true) {
                checked_idx = idx;
            }
        });
        this.props.onChange(this.props.dataName, checked_idx);
    }
    render() {
        let ret: JSX.Element[] = [];
        for (var key in this.props.data) {
            ret.push(
                <ListItem
                    key={key.toString()}
                >
                    <div className='left'>
                        <Checkbox
                            style={{ color: 'black' }}
                            modifier='material'
                            key={key.toString()}
                            value={key}
                            inputId={this.props.keyIndex + key.toString()}
                            checked={this.state.checked[key]}
                            onChange={this.onChange}
                        >

                        </Checkbox>
                    </div>
                    <div
                        className='center'
                    >
                        {this.props.data[key]}
                    </div>
                </ListItem>
            );
        }
        return (
            <div>
                {ret}
            </div>
        )
    }
}

class MyCoordinates {
    public accuracy: number = 0;
    public altitude: number | null = null;
    public altitudeAccuracy: number | null = null;
    public heading: number | null = null;
    public latitude: number = 0;
    public longitude: number = 0;
    public speed: number | null = null;
    constructor() {
    }
    static FromCoordinates(coordinates: Coordinates): MyCoordinates {
        const o = new this();
        o.accuracy = coordinates.accuracy;
        o.altitude = coordinates.altitude;
        o.altitudeAccuracy = coordinates.altitudeAccuracy;
        o.heading = coordinates.heading;
        o.latitude = coordinates.latitude;
        o.longitude = coordinates.longitude;
        o.speed = coordinates.speed
        return o;
    }
    static FromValues(latitude: number, longitude: number, accuracy: number): MyCoordinates {
        const o = new this();
        o.latitude = latitude;
        o.longitude = longitude;
        o.accuracy = accuracy;
        return o;
    }
}

class MyPosition {
    public coords: MyCoordinates;
    public timestamp: number;
    constructor(position?: Position) {
        if (position) {
            this.coords = MyCoordinates.FromCoordinates(position.coords);
            this.timestamp = position.timestamp;
        } else {
            this.coords = new MyCoordinates();
            this.timestamp = 0;
        }
    }
}

interface DeviceLocationViewProps {
    status: GeolocationDetectionStatus
    position: MyPosition
    positionError?: PositionError
}

class DeviceLocationView extends React.Component<DeviceLocationViewProps, any>{
    constructor(props: DeviceLocationViewProps) {
        super(props);
        this.state = {
            currentZoom: 12
        }
    }
    onZoomEnd = (ev: any) => {
        // console.log('zoom end!!! zoom='+ev.target._zoom);
        this.setState({
            currentZoom: ev.target._zoom
        })
    }
    render() {
        if (this.props.status == GeolocationDetectionStatus.apiNotImplemented) {
            return (<div>この端末は位置情報取得をサポートしていません</div>)
        }
        if (this.props.status == GeolocationDetectionStatus.disabled) {
            return (
                <div className='devicelocationview-closed'></div>
            )
        }
        if (this.props.status == GeolocationDetectionStatus.running) {
            return (<div>測位中...完了までお待ちください</div>)
        }
        if (this.props.status == GeolocationDetectionStatus.error && this.props.positionError != null) {
            return (
                <div>
                    <div style={{ color: 'red' }}>
                        位置情報を取得できません
                    </div>
                    <div style={{ fontSize: '15px' }}>
                        {this.props.positionError.message}(error_code:{this.props.positionError.code})
                    </div>
                </div>
            )
        }
        if (this.props.status == GeolocationDetectionStatus.detected) {
            const position: [number, number] = [
                this.props.position.coords.latitude,
                this.props.position.coords.longitude
            ]
            return (
                <div className='devicelocationview-open'>
                    <Map
                        center={position}
                        zoom={this.state.currentZoom}
                        dragging={false}
                        scrollWheelZoom={false}
                        onZoomEnd={this.onZoomEnd}
                    >
                        <TileLayer
                            url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
                            attribution="&copy; <a>国土地理院</a>"
                        />
                        <CircleMarker
                            center={position}
                            radius={5}
                            color='#0000FF'
                            fillOpacity={1}
                        >
                        </CircleMarker>
                        <Circle
                            center={position}
                            radius={this.props.position.coords.accuracy}
                            color="#0000FF"
                            opacity={0.2}
                        >
                        </Circle>
                    </Map>
                </div>
            )
        }
    }
}

interface DeviceGeolocationToggleSwitchProps {
    onUpdate: (position: MyPosition) => void
    onError: () => void
    onCancel: () => void
}
enum GeolocationDetectionStatus {
    running,
    detected,
    error,
    disabled,
    apiNotImplemented
}
class DeviceGeolocationToggleSwitch extends React.Component<DeviceGeolocationToggleSwitchProps, any>{
    private geolocationAPIWatchId: number | null = null;
    constructor(props: DeviceGeolocationToggleSwitchProps, state: any) {
        super(props);

        let geolocationAPIImplemented: boolean;
        if (navigator.geolocation) {
            geolocationAPIImplemented = true;
        } else {
            geolocationAPIImplemented = false;
        }

        this.state = {
            geolocationAPIImplemented: geolocationAPIImplemented,
            enableGPSLocation: false,
            geolocationStatus: GeolocationDetectionStatus.disabled,
            geolocationPosition: null,
            geolocaitonPositionError: null
        }
    }
    componentWillUnmount = () => {
        if (this.geolocationAPIWatchId) {
            navigator.geolocation.clearWatch(this.geolocationAPIWatchId);
        }
    }
    onLocationDetectUpdate = (position: MyPosition) => {
        console.log('GPS:' + position);
        const pos = new MyPosition(position);
        this.setState({
            geolocationStatus: GeolocationDetectionStatus.detected,
            geolocationPosition: pos
        })
        this.props.onUpdate(pos)
    }
    onLocationDetectFailure = (positionError: PositionError) => {
        console.log("GPS Failed")
        // navigator.geolocation.clearWatch(this.state.geolocationWatchId);
        this.setState({
            // エラー時の設定
            geolocationStatus: GeolocationDetectionStatus.error,
            geolocationPositionError: positionError
        })
        this.props.onError();
    }
    startLocationDetection = () => {
        this.setState({
            geolocationStatus: GeolocationDetectionStatus.running
        })
        this.geolocationAPIWatchId = navigator.geolocation.watchPosition(
            this.onLocationDetectUpdate,
            this.onLocationDetectFailure,
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 10000
            }
        )
    }
    stopLocationDetection = () => {
        this.setState({
            geolocationStatus: GeolocationDetectionStatus.disabled,
            geolocationPosition: null,
            geolocationPositionError: null
        })
        if (this.geolocationAPIWatchId != null) {
            navigator.geolocation.clearWatch(this.geolocationAPIWatchId);
        }
        this.props.onCancel();
    }
    onLocationSwitchChanged = (ev: any) => {
        this.setState({
            enableGPSLocation: ev.detail.value
        })
        if (ev.detail.value == true) {
            this.startLocationDetection();
        } else {
            this.stopLocationDetection();
        }
    }
    render() {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <Switch
                        onChange={this.onLocationSwitchChanged}
                        checked={this.state.enableGPSLocation}
                        disabled={!this.state.geolocationAPIImplemented}
                    >
                    </Switch>
                    <div>この端末の位置情報を含める</div>
                </div>
                <DeviceLocationView
                    status={this.state.geolocationStatus}
                    position={this.state.geolocationPosition}
                    positionError={this.state.geolocationPositionError}
                >
                </DeviceLocationView>
            </div>
        )
    }
}

class PersonSafetyInputUi extends React.Component<any, any>{
    private geolocationAPIWatchId: number | null = null;
    constructor(props: any) {
        super(props);

        this.state = {
            person_safety: -1,
            family_safety: -1,
            location: -1,
            damage_situation: -1,
            coop_company_workers: -1,
            workability: -1,
            message: '',
            geolocationPosition: null,
            viewportHeight: window.innerHeight + 'px',
            errorMessage: '',
            isAlertDialogOpen: false,
            alertDialogTitle: '',
            alertDialogContent: null,
            alertDialogOkHandler: null,
        }
        // this._resize();
    }
    componentDidMount = () => {
        window.addEventListener('resize', this._resize);
    }
    componentWillUnmount = () => {
        window.removeEventListener('resize', this._resize);
    }

    _resize = () => {
        this.setState({
            viewportHeight: window.innerHeight + 'px'
        })
        console.log('####innerHeight:' + window.innerHeight)
    }
    checkboxOnChange = (k: string, v: number) => {
        let dict: { [key: string]: number } = {};
        dict[k] = v;
        this.setState(dict)
    }
    onTextAreaChange = (ev: any) => {
        this.setState({
            message: ev.target.value
        })
    }
    onSubmit = () => {
        let status: any = {};
        status['person_safety'] = this.state.person_safety;
        status['family_safety'] = this.state.family_safety;
        status['location'] = this.state.location;
        status['damage_situation'] = this.state.damage_situation;
        status['coop_company_workers'] = this.state.coop_company_workers;
        status['workability'] = this.state.workability;

        let status_jp: any = {};
        status_jp['person_safety'] = '本人安否';
        status_jp['family_safety'] = '家族安否';
        status_jp['location'] = '現在地点';
        status_jp['damage_situation'] = '物的損害状況';
        status_jp['coop_company_workers'] = '協力会社作業員';
        status_jp['workability'] = '出社可否';

        // Are all status selected??
        // console.log('###################################Are all status selected?')
        for (var s in status) {
            if (status[s] == -1) {
                this.setState({
                    errorMessage: '選択されていません：' + status_jp[s]
                })
                return
            }
        }

        let response: any = {}
        response['message'] = { 'body': this.state.message };
        response['user_status'] = status;

        // Location info
        if (this.state.geolocationPosition) {
            response['location'] =
            {
                'latitude': this.state.geolocationPosition.coords.latitude,
                'longitude': this.state.geolocationPosition.coords.longitude,
                'accuracy': this.state.geolocationPosition.coords.accuracy
            }
        }

        APIs.getInstance().postMessage(this.props.thread_summary.thread_id, response)
            .then(json => {
                this.props.onClose();
                this.props.onSubmit();
                this.setState({
                    errorMessage: ''
                })
                AppController.getInstance().notify(json.status);
            })
            .catch(err => {
                console.log(err)
                AppController.getInstance().notify(err);
            })
    }
    onQuickSubmitPreInteraction = (loc_id: number) => {

        const content = (
            <div style={{ justifyContent: 'left' }}>
                <p style={{ textAlign: 'left' }}>以下の内容で安否登録を行います.</p>
                <p style={{ textAlign: 'left', margin: '0px 3px', marginLeft: '1em' }}>本人安否：無事</p>
                <p style={{ textAlign: 'left', margin: '0px 3px', marginLeft: '1em' }}>家族安否：全員無事</p>
                <p style={{ textAlign: 'left', margin: '0px 3px', marginLeft: '1em' }}>現在地点：{PersonSafetyConst.Location[loc_id]}</p>
                <p style={{ textAlign: 'left', margin: '0px 3px', marginLeft: '1em' }}>物的被害状況：なし</p>
                <p style={{ textAlign: 'left', margin: '0px 3px', marginLeft: '1em' }}>協力会社作業員：いない</p>
                <p style={{ textAlign: 'left', margin: '0px 3px', marginLeft: '1em' }}>出社可否：通常出社可能</p>
                <p style={{ textAlign: 'left', margin: '0px 3px', marginLeft: '1em' }}>特記事項：なし</p>
                <p style={{ textAlign: 'left', margin: '0px 3px', marginLeft: '1em' }}>端末位置情報：送信しない</p>
            </div>
        )

        this.setState({
            isAlertDialogOpen: true,
            alertDialogTitle: 'クイック回答',
            alertDialogContent: content,
            alertDialogOkHandler: () => {
                this.setState({
                    person_safety: 0,
                    family_safety: 0,
                    location: loc_id,
                    damage_situation: 0,
                    coop_company_workers: 0,
                    workability: 0,
                    message: '',
                    geolocationPosition: null,
                },
                    this.onSubmit)
            }
        })
    }

    onQuickSubmitPreInteractionOK = () => {
        this.state.alertDialogOkHandler();
        this.setState({
            isAlertDialogOpen: false
        })
    }

    onGeolocationUpdate = (position: MyPosition) => {
        this.setState({
            geolocationPosition: position
        })
    }
    onGeolocationError = () => {
        this.setState({
            geolocationPosition: null
        })
    }
    onGeolocationCancel = () => {
        this.setState({
            geolocationPosition: null
        })
    }

    renderAlertDialog = () => {
        return (
            <AlertDialog
                isOpen={this.state.isAlertDialogOpen}
                onCancel={() => { this.setState({ isAlertDialogOpen: false }) }}
                isCancelable={true}
                modifier='rowfooter'
            >
                <div className="alert-dialog-title">{this.state.alertDialogTitle}</div>
                <div className="alert-dialog-content">
                    {this.state.alertDialogContent}
                </div>
                <div className="alert-dialog-footer" style={{ display: 'flex' }}>
                    <Button style={{ width: '50%' }} onClick={() => { this.setState({ isAlertDialogOpen: false }) }} className="alert-dialog-button">
                        CANCEL
                    </Button>
                    <Button style={{ width: '50%' }} onClick={this.onQuickSubmitPreInteractionOK} className="alert-dialog-button">
                        OK
                    </Button>
                </div>

            </AlertDialog>
        )

    }
    render() {
        return (
            <div
                style={{
                    width: '100%',
                    // height:this.state.viewportHeight,
                    height: '100%',
                    // background: 'rgb(255,255,255)', 
                    textAlign: "initial"
                }}
                className='smooth-scroll'
            >
                {this.renderAlertDialog()}
                <div
                    style={{ height: '100%' }}
                    className='smooth-scroll'
                >
                    <div style={{ color: 'black', marginLeft: '10px', marginTop: '10px' }}>安否状況を入力して下さい</div>
                    <div className='__caption_box'>
                        <p className='__caption_box__caption'>被害なし時クイック回答(現在地選択)</p>
                        <div className='__caption_box_caption_buttonwrapper'>
                            <Button onClick={() => { this.onQuickSubmitPreInteraction(0) }}>押上</Button>
                            <Button onClick={() => { this.onQuickSubmitPreInteraction(1) }}>現場</Button>
                            <Button onClick={() => { this.onQuickSubmitPreInteraction(2) }}>自宅</Button>
                            <Button onClick={() => { this.onQuickSubmitPreInteraction(3) }}>その他</Button>
                        </div>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div>
                            <div style={{ color: 'black', marginTop: '5px' }}>本人安否</div>
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                <Checkboxes
                                    data={PersonSafetyConst.PersonSafety}
                                    dataName={'person_safety'}
                                    onChange={this.checkboxOnChange}
                                    key={'SELF'}
                                ></Checkboxes>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div>
                            <div style={{ color: 'black', marginTop: '10px' }}>家族安否</div>
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                <Checkboxes
                                    data={PersonSafetyConst.FamilySafety}
                                    dataName={'family_safety'}
                                    onChange={this.checkboxOnChange}
                                    key={'FAMILY'}
                                ></Checkboxes>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div>
                            <div style={{ color: 'black', marginTop: '10px' }}>現在地点</div>
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                <Checkboxes
                                    data={PersonSafetyConst.Location}
                                    dataName={'location'}
                                    onChange={this.checkboxOnChange}
                                    key={'LOCATION'}
                                ></Checkboxes>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div>
                            <div style={{ color: 'black', marginTop: '10px' }}>物的被害状況</div>
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                <Checkboxes
                                    data={PersonSafetyConst.DamageSituation}
                                    dataName={'damage_situation'}
                                    onChange={this.checkboxOnChange}
                                    key={'DAMAGE'}
                                ></Checkboxes>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div>
                            <div style={{ color: 'black', marginTop: '10px' }}>協力会社作業員(現場代理人のみ報告すること)</div>
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                <Checkboxes
                                    data={PersonSafetyConst.CoopCompanyWorkers}
                                    dataName={'coop_company_workers'}
                                    onChange={this.checkboxOnChange}
                                    key={'DAMAGE'}
                                ></Checkboxes>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div>
                            <div style={{ color: 'black', marginTop: '10px' }}>出社可否</div>
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                <Checkboxes
                                    data={PersonSafetyConst.Workability}
                                    dataName={'workability'}
                                    onChange={this.checkboxOnChange}
                                    key={'WORKABILITY'}
                                ></Checkboxes>
                            </div>
                        </div>
                    </div>
                    <div>
                        <hr style={{ width: '100%', color: 'black', borderTop: '1px solid #8c8b8b' }}></hr>
                    </div>
                    <div style={{ marginLeft: '5px' }}>
                        <div>
                            <DeviceGeolocationToggleSwitch
                                onUpdate={this.onGeolocationUpdate}
                                onError={this.onGeolocationError}
                                onCancel={this.onGeolocationCancel}
                            >
                            </DeviceGeolocationToggleSwitch>
                        </div>
                    </div>
                    <div>
                        <hr style={{ width: '100%', color: 'black', borderTop: '1px solid #8c8b8b' }}></hr>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <div>
                            <textarea
                                style={{
                                    width: '98%', fontSize: '1em', borderStyle: 'solid 1px',
                                    borderRadius: '0.5em', height: '4em', lineHeight: '1em'
                                }}
                                placeholder='特記事項がある場合のみ簡潔に入力してください'
                                value={this.state.message}
                                onChange={this.onTextAreaChange}
                            >
                            </textarea>
                        </div>
                    </div>
                    <div
                        style={{
                            color: 'red',
                            height: '1em'
                        }}
                    >
                        {this.state.errorMessage}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                        <div style={{ width: 'auto', margin: '10px' }}>
                            <Button
                                onClick={this.props.onClose}
                            >
                                キャンセル
                            </Button>
                        </div>
                        <div style={{ width: 'auto', margin: '10px' }}>
                            <Button
                                onClick={this.onSubmit}
                            >
                                登録
                            </Button>
                        </div>
                    </div>
                    {/* <div style={{height:'50px'}}></div> */}

                </div>
            </div>
        )
    }
}

class OverlayPage extends React.Component<any, any>{
    private noAnimation: boolean;
    constructor(props: any) {
        super(props);
        this.noAnimation = !this.props.isOpen;
    }
    render() {
        let cname: string;
        if (this.props.isOpen) {
            cname = '__overlaypage_is_open';
            this.noAnimation = false;
        } else {
            if (this.noAnimation) {
                cname = '__overlaypage_is_closed_no_animation'
            } else {
                cname = '__overlaypage_is_closed';
            }
        }
        return (
            <div className={cname} style={{ width: '100vw', zIndex: 20000 }}>
                {this.props.children}
            </div>
        )
    }
}

class MessageEditor extends React.Component<any, any>{
    // private isThreadOwner:boolean;
    constructor(props: any) {
        super(props)

        //安否登録ずみか確認
        let responses: any = this.props.thread_summary.responses;
        let person_safety_is_open: boolean = this.props.isMyThread;
        for (var i in responses) {
            if (responses[i].types & MessageType.UserStatus) {
                person_safety_is_open = false;
            }
        }

        this.state = {
            message: '',
            textareaHeight: '',
            extended_attachment: false,
            extended_safety: false,
            buttonOpacity: 1.0,
            personSafetyUiIsOpen: person_safety_is_open,
            alertDialogShouldOpen: !this.props.isMyThread,
            textContent: '',
            textAreaHeight: 'auto'
        }
    }
    render_attachment() {
        // return
    }

    // openPersonSafetyUi = () => {
    //     this.setState({
    //         'personSafetyUiIsOpen': true
    //     })
    // }

    onSendButtonClick = (ev: any) => {

        // 押したアニメーション
        // ev.target.style.opacity;
        this.setState({
            buttonOpacity: 0.5
        })
        setTimeout(() => {
            this.setState({
                buttonOpacity: 1.0
            })
        }, 100);

        // 入力が空なら送信しない
        if (this.state.textContent === "") {
            return
        }
        // 送信する
        let response: any = {}
        response['message'] = { 'body': this.state.textContent };
        console.log('sending .. ' + this.state.textContent);

        APIs.getInstance().postMessage(this.props.thread_summary.thread_id, response)
            .then(json => {
                // AppController.getInstance().notify(json.status);
                this.setState({ textContent: '' })
                this.props.update();
            })
            .catch(err => {
                AppController.getInstance().notify(err);
            })
    }

    personSafetyUiOnSubmit = (data: any) => {
        this.props.update();
        this.setState({
            alertDialogShouldOpen: !this.props.isMyThread
        })
    }

    personSafetyUiOnClose = () => {
        this.setState({
            personSafetyUiIsOpen: false,
            // alertDialogShouldOpen:true
            alertDialogShouldOpen: !this.props.isMyThread
        })
    }
    // setTextareaHeight = (h:number) => {
    //     AppController.getInstance().notify(h)
    //     return( () => {
    //             console.log('adjusting height to '+ h + 'px')
    //             this.setState({textareaHeight:h+'px'})
    //         }
    //     )
    // }
    onMessageTextAreaChanged = (ev: any) => {
        this.setState({
            textContent: ev.target.value
        })
        console.log(ev)
    }


    render_base() {
        console.log('rendering base')
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', border: '5px' }}>
                <div
                    style={{
                        width: '30px', height: '30px', borderRadius: '15em',
                        backgroundColor: '#538af0', textAlign: 'center', lineHeight: '30px', marginLeft: '0.25em'
                    }}>
                    +
                </div>
                <div
                    style={{
                        width: '4em', height: '30px', borderRadius: '15em',
                        backgroundColor: '#538af0', textAlign: 'center', lineHeight: '30px', marginLeft: '0.25em'
                    }}
                    onClick={() => { this.setState({ personSafetyUiIsOpen: true }) }}
                >
                    安否
                </div>

                <TextareaAutosize
                    style={{
                        borderRadius: '18px',
                        paddingLeft: '9px',
                        paddingRight: '9px'
                    }}
                    placeholder='Aa'
                    id='MessageTextInputArea'
                    value={this.state.textContent}
                    onChange={this.onMessageTextAreaChanged}
                >

                </TextareaAutosize>
                <div
                    style={{
                        width: '4em', height: '30px', borderRadius: '15px', marginRight: '0.25em',
                        backgroundColor: '#538af0', textAlign: 'center', lineHeight: '30px', opacity: this.state.buttonOpacity
                    }}
                    onClick={this.onSendButtonClick}
                >
                    送信
                </div>
            </div>
        )
    }
    render() {

        return (
            <div>
                <OverlayPage style={{ zIndex: 10 }} isOpen={this.state.personSafetyUiIsOpen}>
                    {/* <OverlayPage style={{ zIndex: 10 }} isOpen={true}> */}
                    {/* <Page contentStyle={{ zIndex: 10, display:'none' }}> */}
                    {/* <Modal style={{ zIndex: 10 }} isOpen={true}> */}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', zIndex: 10 }}>
                        <div>
                            <AlertDialog
                                isOpen={this.state.alertDialogShouldOpen && this.state.personSafetyUiIsOpen}
                            // modifier='material'
                            >
                                <div className="alert-dialog-title">代理入力画面</div>
                                <div className="alert-dialog-content">
                                    {this.props.thread_summary.owner_user_name}さんの代理として安否状況を入力してください
                                </div>
                                <div className="alert-dialog-footer">
                                    {/* <Button onClick={this.personSafetyUiOnClose} className="alert-dialog-button">
                                        Cancel
                                    </Button> */}
                                    <Button onClick={() => { this.setState({ alertDialogShouldOpen: false }) }} className="alert-dialog-button">
                                        Ok
                                    </Button>
                                </div>
                            </AlertDialog>
                        </div>
                        {/* <div style={{ width: '100%', height: '100vh' }}> */}
                        <PersonSafetyInputUi
                            thread_summary={this.props.thread_summary}
                            onSubmit={this.personSafetyUiOnSubmit}
                            onClose={this.personSafetyUiOnClose}
                        >
                        </PersonSafetyInputUi>
                        {/* </div> */}
                    </div>
                </OverlayPage>
                <div>
                    {this.render_base()}
                </div>
            </div>
        )
    }

}

class ThreadMessage extends React.Component<any, any>{
    constructor(props: any, s: any) {
        super(props);
        this.state = {
            myId: AppController.getInstance().getLoginUserInfo().user_id
        }
    }
    renderMessage() {
        if (this.props.response.types & MessageType.Message) {
            let msg: string;
            if (this.props.response.types & MessageType.UserStatus) {
                if (this.props.response.message.body === '') {
                    msg = '(特記事項なし)'
                } else {
                    msg = this.props.response.message.body;
                }
            } else {
                msg = this.props.response.message.body;
            }
            return (
                <div
                    style={{
                        whiteSpace: 'pre-line', wordBreak: 'break-all', padding: '0.5em',
                        lineHeight: '1.2em'
                    }}>
                    {msg}
                </div>
            )
        } else {
            return (<div></div>)
        }
    }
    renderUserStatus() {

        if (this.props.response.types & MessageType.UserStatus) {
            const status: any = this.props.response.status
            const person_safety = status.person_safety != -1 ?
                PersonSafetyConst.PersonSafetySN[status.person_safety] :
                '(未回答)';
            const family_safety = status.family_safety != -1 ?
                PersonSafetyConst.FamilySafetySN[status.family_safety] :
                '(未回答)';
            const location = status.location != -1 ?
                PersonSafetyConst.LocationSN[status.location] :
                '(未回答)';
            const damage_situation = status.damage_situation != -1 ?
                PersonSafetyConst.DamageSituationSN[status.damage_situation] :
                '(未回答)';
            const coop_company_workers = status.coop_company_workers != -1 ?
                PersonSafetyConst.CoopCompanyWorkersSN[status.coop_company_workers] :
                '(未回答)';
            const workability = status.workability != -1 ?
                PersonSafetyConst.WorkabilitySN[status.workability] :
                '(未回答)';
            return (
                <div style={{
                    width: '100%',
                    backgroundColor: '#009900',
                    fontSize: '1em',
                    lineHeight: '1em',
                    borderRadius: '0 0 15px 15px'
                }}>
                    <div style={{ padding: '0.5em' }}>
                        <div>//安否状況が登録されました//</div>
                        <div>//自動集計対象は最新の安否状況登録のみ//</div>
                        <div>
                            <div>
                                <a>本人：</a>
                                <a>{person_safety}</a>
                            </div>
                            <div>
                                <a>家族：</a>
                                <a>{family_safety}</a>
                            </div>
                            <div>
                                <a>現在地：</a>
                                <a>{location}</a>
                            </div>
                            <div>
                                <a>被害状況：</a>
                                <a>{damage_situation}</a>
                            </div>
                            <div>
                                <a>協力会社従業員：</a>
                                <a>{coop_company_workers}</a>
                            </div>
                            <div>
                                <a>出社可否：</a>
                                <a>{workability}</a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<div></div>)
        }
    }
    renderGeolocation() {
        if (!(this.props.response.types & MessageType.Location)) {
            return (<div></div>)
        }

        let locrcv = this.props.response.location
        const pos = new MyPosition();
        pos.coords = MyCoordinates.FromValues(locrcv.latitude, locrcv.longitude, locrcv.accuracy);
        pos.timestamp = 0;

        return (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                <div style={{ width: '95%', marginLeft: '2.5%', marginTop: '5px', marginBottom: '5px' }}>
                    <DeviceLocationView
                        status={GeolocationDetectionStatus.detected}
                        position={pos}
                    >
                    </DeviceLocationView>
                </div>
            </div>
        )
    }
    renderContents() {
        // this.props.response
        return (
            <div>
                {this.renderMessage()}
                {this.renderUserStatus()}
                {this.renderGeolocation()}
            </div>
        )
    }
    render() {
        let side: string;
        let bgcolor: string;
        if (this.state.myId === this.props.response.creater_id) {
            side = '0 10px 0 auto'
            bgcolor = '#9bdf61'
        } else {
            side = '0 auto 0 10px'
            bgcolor = 'ghostwhite'
        }
        return (
            <div id={this.props.id} style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}>
                <div style={{ width: '80%', margin: side }}>
                    {/* 1段目 */}
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        {/* 送信者名 */}
                        <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ fontSize: '0.8em', marginLeft: '12px' }}>
                                {this.props.response.creater_name}
                            </div>
                        </div>
                        {/* 送信日時 */}
                        <div style={{ width: '50%', display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                            <div style={{ fontSize: '0.8em', marginRight: '12px' }}>
                                {UTC2Local(this.props.response.created_at)}
                            </div>
                        </div>
                    </div>
                    {/* 緑枠 */}
                    <div style={{ borderRadius: '15px', backgroundColor: bgcolor }}>
                        {this.renderContents()}
                    </div>

                </div>
            </div>
        )
    }
}

class LargeCheckBox extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        // this.state = {
        //     checked:props.checked
        // }
    }
    render() {
        let isChecked: string = this.props.checked === true ? 'checked' : ''
        return (
            <div
                onClick={this.props.onClick}
                className={'large-check-box ' + isChecked}
            ></div>
        )
    }
}

export class ThreadViewer extends NavigatorChildBase {
    public EditorToolbarRenderer: () => void = () => {
        return (
            <Toolbar id='Toolbar'>
                <div className='left'><BackButton onClick={this.onBackButton}>戻る</BackButton></div>
                <div
                    className='center'
                    onClick={this.onTitleClick}
                >
                    {this.state.pageTitle}
                </div>
                <div className='right'>
                    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                        {/* <div style={{ width: '100%' }}> */}
                        {/* <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: '60%', height: '60%', borderStyle: 'solid', borderWidth: '2px', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                ピ
                            </div>
                        </div> */}
                        <div
                            style={{
                                width: '60%', display: 'flex', flexDirection: 'column'
                            }}
                            onClick={this.update}
                        >
                            <div style={{ width: '100%', height: '50%', fontSize: '0.6em', lineHeight: '1em', margin: '0 0 0 0', textAlign: 'left' }}>
                                最終更新：
                            </div>
                            <div style={{ width: '100%', height: '50%', fontSize: '0.6em', lineHeight: '1em', margin: '0 0 0 0', textAlign: 'left' }}>
                                {this.state.elapsedTimeLastUpdatedString}
                            </div>
                        </div>
                        <div style={{ width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {/* <img style={{height:'100%', width:'auto'}} src={mail_icon}></img> */}
                            <a style={{ width: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                href={'tel:' + this.state.user_phone_number}>
                                <img style={{ width: '70%', height: '70%', maxHeight: '35px' }} src={telehphone_icon}></img>
                            </a>
                            {/* <LargeCheckBox 
                                checked={this.state.thread_summary.current_status === ThreadStatus.closed} 
                                onClick={this.flipThreadStatus}
                            ></LargeCheckBox> */}

                        </div>

                    </div>
                </div>
            </Toolbar>
        );
    }
    private pollingIntervalId: NodeJS.Timer | null = null;
    constructor(props: any, state: any) {
        super(props, state);


        this.state = {
            pageTitle: '#' + this.props.thread_summary.sequence_id + ' ' +
                this.props.thread_summary.owner_user_name,
            responses: [],
            thread_summary: this.props.thread_summary,
            loading: true,
            alertDialogIsOpen: false,
            alertMessage: '',
            alertOnOk: null,
            lastUpdated: '',
            elapsedTimeLastUpdatedString: '',
            shouldScrollInto: true,
            user_phone_number: '0000'
        }
        if (this.props.selfpolling === undefined) {
            console.log(this.props.selfpolling)
            this.props.registerCallbackWhenComponentDidUpdate(this.parentDidUpdated);
        } else {
            this.registerPollingUpdate(this.parentDidUpdated, POLLING_INTERVAL_MILLIS);
        }
        this.update()
            .then(() => {
                this.onChangeWindowHeight()
            })
        // this.onChangeWindowHeight();
        APIs.getInstance().setUnreadResponseByThread(this.props.thread_summary.thread_id);
        APIs.getInstance().getUsers([this.props.thread_summary.owner_user_id])
            .then(userInfos => {
                this.setState({ user_phone_number: userInfos[0].phone_number },
                    AppController.getInstance().applyToolbarRendererNow)
            })

        AppController.getInstance().registerToolbarRenderer(this.props.index, this.EditorToolbarRenderer);
        AppController.getInstance().applyToolbarRendererNow();

        setInterval(() => {
            let elapsedsec = (Date.now() - this.state.lastUpdated) / 1000
            let elapsedstr = '';
            if (elapsedsec < 60) {
                elapsedstr = Math.floor(elapsedsec) + '秒前'
            } else {
                elapsedstr = Math.floor(elapsedsec / 60) + '分前'
            }
            this.setState({
                elapsedTimeLastUpdatedString: elapsedstr
            }, () => { AppController.getInstance().forceRerender() })
        }, 1000)
    }
    onBackButton = () => {
        if (this.props.updatePrevThreadUnreadResponse !== undefined) {
            this.props.updatePrevThreadUnreadResponse();
        }
        super.onBackButton();
    }
    onTitleClick = () => {
        // alert(this.props.thread_summary.owner_user_id);
        this.props.navigator.pushPage({
            component: UserAccountInfoPage,
            title: 'UserAccountInfoPage',
            _props: Object.assign(
                {
                    user_id: this.props.thread_summary.owner_user_id
                },
                this.props
            )
        });
    }
    componentWillUnmount = () => {
        if (this.props.selfpolling === undefined) {
            this.props.unregisterCallbackWhenComponentDidUpdate();
        } else {
            this.unregisterPollingUpdate();
        }
        window.removeEventListener('resize', this.onChangeWindowHeight);
    }
    parentDidUpdated = () => {
        this.updateData();
    }
    componentDidMount = () => {
        window.addEventListener('resize', this.onChangeWindowHeight);
    }
    getHeight = () => {
        const page = document.getElementById('ThreadViewerPage');
        if (page !== null) {
            const h = page.clientHeight;
            // AppController.getInstance().notify(h);
            return h;
        }
        return null
    }
    changeWindowHeight = () => {
        const ch = this.getHeight();
        this.setState({ height: ch })
    }
    onChangeWindowHeight = () => {
        setTimeout(this.changeWindowHeight, 200);
    }
    registerPollingUpdate = (func: any, interval: number) => {
        if (this.pollingIntervalId != null) {
            this.unregisterPollingUpdate();
        }
        let intervalId = setInterval(func, interval);
        this.pollingIntervalId = intervalId;
    }
    unregisterPollingUpdate = () => {
        if (this.pollingIntervalId != null) {
            clearInterval(this.pollingIntervalId)
            this.pollingIntervalId = null;
        }
    }
    updateData = () => {
        APIs.getInstance().getThread(this.state.thread_summary.thread_id)
            .then(json => {
                this.setState({
                    responses: json,
                    loading: false,
                    lastUpdated: Date.now()
                }, () => {
                    if (this.state.shouldScrollInto) {
                        let responses = this.state.responses;
                        let lastResponseId = responses[responses.length - 1].response_id;
                        let target = document.getElementById('__thread_message_response_id_' + lastResponseId);
                        if (target) {
                            setTimeout(() => {
                                if (target) {
                                    target.scrollIntoView(
                                        {
                                            behavior: 'smooth',
                                        }
                                    );
                                }
                                this.setState({ shouldScrollInto: false });
                            }, 400)
                        };
                    }
                    AppController.getInstance().forceRerender();
                })
            })
            .catch(err => {
                AppController.getInstance().notify(err);
                this.setState({
                    loading: false
                })
            })
    }

    private isThreadClosed = () => {
        return this.state.thread_summary.current_status === ThreadStatus.closed
    }
    update = () => {
        APIs.getInstance().getThreadSummary(this.state.thread_summary.thread_id)
            .then(json => {
                this.setState({
                    thread_summary: json
                })
            })
            .then(() => {
                // this.props.registerToolbarRenderer(this.props.index, this.EditorToolbarRenderer);
                // this.props.applyToolbarRendererNow();
                AppController.getInstance().forceRerender();
            })
            .catch(err => {
                AppController.getInstance().notify(err);
            })
        this.updateData();

        if (this.props.updateThreads != undefined) {
            this.props.updateThreads();
        }

        return Promise.resolve()
    }
    flipThreadStatus = () => {
        let proc: string
        if (this.state.thread_summary.current_status === ThreadStatus.open) {
            proc = '完了状態'
        } else {
            proc = '未完了状態'
        }
        this.setState({
            alertDialogIsOpen: true,
            alertMessage: 'このスレッドを' + proc + 'にしますか？',
            alertOnOk: this._doFlipThreadStatus
        })
    }
    _doFlipThreadStatus = () => {
        APIs.getInstance().flipThreadStatus(this.state.thread_summary.thread_id)
            .then(json => {
                // AppController.getInstance().notify(json.status);
            })
            .then(() => {
                this.update();
            })
            .catch(err => {
                AppController.getInstance().notify(err);
            })
        this.setState({
            alertDialogIsOpen: false
        })
    }
    render_messages() {
        let msgelem: JSX.Element[] = [];
        this.state.responses.map((element: any) => {
            msgelem.push(
                <ThreadMessage
                    response={element}
                    key={element.response_id}
                    id={'__thread_message_response_id_' + element.response_id}
                >
                </ThreadMessage>
            )
        })
        return (
            msgelem
        )
    }

    render() {
        let isMyThread: boolean = false;
        let myInfo = AppController.getInstance().getLoginUserInfo();
        if (myInfo.user_id === this.state.thread_summary.owner_user_id) {
            isMyThread = true;
        }
        let progress_circle: JSX.Element = <div></div>
        if (this.state.loading) {
            progress_circle = (
                <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ProgressCircular indeterminate ></ProgressCircular>
                </div>
            )
        }
        return (
            <Page id='ThreadViewerPage'>
                {progress_circle}
                <AlertDialog isOpen={this.state.alertDialogIsOpen}>
                    <div className="alert-dialog-content">
                        {this.state.alertMessage}
                    </div>
                    <div className="alert-dialog-footer">
                        <Button onClick={() => { this.setState({ alertDialogIsOpen: false }) }} className="alert-dialog-button">
                            Cancel
                        </Button>
                        <Button onClick={this.state.alertOnOk} className="alert-dialog-button">
                            OK
                        </Button>
                    </div>
                </AlertDialog>
                {/* <div style={{ minHeight: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}> */}
                <div style={{ minHeight: this.state.height + 'px', maxHeight: this.state.height + 'px', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '100%', flex: 1, overflow: 'scroll', backgroundColor: '#7895c0' }}>
                        {this.render_messages()}
                    </div>
                    <div style={{ height: 'auto' }}>
                        <MessageEditor
                            thread_summary={this.state.thread_summary}
                            update={this.update}
                            isMyThread={isMyThread}
                            onChangeWindowHeight={this.onChangeWindowHeight}
                        ></MessageEditor>
                    </div>
                </div>
            </Page>
        )
    }
}



class ThreadListItem extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            unread_responses: []
        }

        this.updateUnreadResponses()
    }

    public updateUnreadResponses = () => {
        APIs.getInstance().getUnreadResponseByThreadId(this.props.threadSummary.thread_id)
            .then(_json => {
                this.setState({
                    unread_responses: _json
                })
            })
    }

    render() {
        const owner_user_name = this.props.threadSummary.owner_user_name;
        let updated_at: string | null = '返信なし';
        if (this.props.threadSummary.updated_at != null) {
            updated_at = UTC2Local(this.props.threadSummary.updated_at);
        }
        let current_message: string;
        if (this.props.threadSummary.current_message == '') {
            if (this.props.threadSummary.responses.slice(-1)[0].types & MessageType.UserStatus) {
                current_message = '安否状況を登録しました';
            } else {
                current_message = '';
            }
        } else {
            current_message = this.props.threadSummary.current_message;
        }

        // const isOpen:boolean = this.props.threadSummary.current_status === ThreadStatus.open ? 
        //     true:false;
        const isRegistered: boolean = this.props.threadSummary.has_user_status

        let bgcolorClass: string = 'threadList-unregistered';
        if (isRegistered) {
            bgcolorClass = 'threadList-registered'
        }
        const isClosed: boolean = (this.props.threadSummary.current_status === ThreadStatus.closed)
        if (isClosed) {
            bgcolorClass = 'threadList-closed'
        }

        let n_unread_responses: string = '';
        if (this.state.unread_responses.length != 0) {
            n_unread_responses = this.state.unread_responses.length.toString();
        }

        let nth_sc: number = 0;
        for (var i = 0; i < this.props.threadSummary.responses.length; i++) {
            if (this.props.threadSummary.responses[i].types & MessageType.UserStatus) {
                nth_sc++;
            }
        }
        let nth_sc_caption: string = '';
        if (nth_sc >= 2) {
            nth_sc_caption = '安否第' + nth_sc.toString() + '報';
        }
        // if(this.props.threadSummary.owner_user_id === 'L3USER'){
        //     console.log(this.props.threadSummary.owner_user_id);
        //     console.log(this.props.threadSummary.current_status);
        //     console.log(bgcolorClass)
        // }

        return (
            <ListItem
                style={{
                    borderTop: 'solid 1px black',
                    borderBottom: 'solid 1px black'
                }}
                className={bgcolorClass + ' message-thread-list-item'}
                onClick={() => { this.props.onListItemClick(this.props.threadSummary, this.updateUnreadResponses) }}
                key={this.props.threadSummary.thread_id + 'ListItem'}
            >
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: '2px' }}>
                    <div style={{ display: 'flex', width: '100%', marginBottom: '0px', marginTop: '0px' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <a style={{ fontSize: '1em' }}>{owner_user_name}</a>
                        </div>
                        <div>
                            <a className='right' style={{ fontSize: '0.7em', marginRight: '1em' }}>{updated_at}</a>
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginBottom: '0px', marginTop: '0px' }}>
                        <div style={{ fontSize: '0.7em', marginTop: '0px', whiteSpace: 'nowrap' }}>
                            {shrinkString(current_message, 24)}
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <div
                                className='notification'
                                style={{ fontSize: '12px' }}
                            >
                                {nth_sc_caption}
                            </div>
                            <div
                                className='notification'
                                style={{ marginLeft: '5px', fontSize: '12px' }}
                            >
                                {n_unread_responses}
                            </div>
                        </div>
                    </div>
                </div>
            </ListItem>
        )
    }
}

class DivisionSelecter extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            divisions: [],
            is_on: {}
        }
        APIs.getInstance().getDivisions()
            .then(json => {
                let _is_on: { [key: string]: boolean } = {};
                json.forEach((element: any) => {
                    _is_on[element.division_id] = true;
                })
                this.setState({
                    divisions: json,
                    is_on: _is_on
                }, this.onSelectionChange)
            })

    }

    onButtonClick = (division_id: string) => {
        let _is_on = this.state.is_on;
        _is_on[division_id] = !_is_on[division_id]
        this.setState({
            is_on: _is_on
        }, this.onSelectionChange)
        // let selectedDivisions:string[] = [];
        // for(var division of this.state.divisions){
        //     console.log(division);
        //     if(_is_on[division.division_id] === true){
        //         selectedDivisions.push(division.division_id);
        //     }
        // }
        // this.props.onSelectedDivisionUpdated(selectedDivisions);

    }
    onSelectionChange = () => {
        let selectedDivisions: string[] = [];
        for (var division of this.state.divisions) {
            console.log(division);
            if (this.state.is_on[division.division_id] === true) {
                selectedDivisions.push(division.division_id);
            }
        }
        this.props.onSelectedDivisionUpdated(selectedDivisions);
    }

    render() {
        if (this.state.divisions.length == 0) {
            return (<div></div>)
        }

        let buttons: JSX.Element[] = [];
        this.state.divisions.forEach((element: any) => {
            let modifier: string;
            if (this.state.is_on[element.division_id]) {
                modifier = 'material'
            } else {
                modifier = 'quiet'
            }

            buttons.push(
                <Button
                    style={{ fontSize: '0.9em', paddingLeft: '0.5em', paddingRight: '0.5em', minHeight: '0.1em' }}
                    modifier={modifier}
                    onClick={() => { this.onButtonClick(element.division_id) }}
                    key={element.division_id}
                >
                    {element.short_name}
                </Button>
            )
        });

        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                {buttons}
            </div>
        )
    }
}

function isDuplicate(arr1: any[], arr2: any[]) {
    return [...arr1, ...arr2].filter(item => arr1.includes(item) && arr2.includes(item)).length > 0
}

export class ThreadList extends React.Component<any, any>{
    private userInfos: any | null = null;
    constructor(props: any) {
        super(props)

        this.state = {
            myInfo: AppController.getInstance().getLoginUserInfo(),
            // displayed_divisions:{}, 
            userInfos: null,
            selectedDivisions: []
        }
        // APIs.getInstance().onMyInfoResolved(this.onMyInfoResolved);

    }
    componentDidMount = () => {
        APIs.getInstance().getUsers([])
            .then(json => {
                this.setState({ userInfos: json })
            })
    }

    onThreadItemClick = (threadId: number) => {
        this.props.notify(threadId);
    }

    private popMyThread = (threadSummaries: any) => {
        if (this.state.myInfo == null) {
            return [];
        }

        let myUserId = this.state.myInfo.user_id;
        let poped: any[] = [];
        for (var i in threadSummaries) {
            if (threadSummaries[i].owner_user_id == myUserId) {
                // let theThread = threadSummaries[i];
                poped.push(threadSummaries.splice(i, 1)[0]);
                // return [theThread];
                return poped;
            }
        }

        // myInfoは解決したがThreadSummariesが未解決の時
        return [];
    }

    private popThreads = (threadSummaries: any, func: any) => {
        let poped: any[] = [];
        for (var i = threadSummaries.length - 1; i >= 0; --i) {
            if (func(threadSummaries[i]) === true) {
                poped.push(threadSummaries.splice(i, 1)[0])
            }
        }
        return poped;
    }

    private popThreadsByOwner = (threadSummaries: any, userInfos: any, func: any) => {

        let poped: any[] = [];
        let userInfo: any | null = null;
        for (var i = threadSummaries.length - 1; i >= 0; --i) {
            userInfo = null;
            for (var j = 0; j < userInfos.length; ++j) {
                if (userInfos[j].user_id === threadSummaries[i].owner_user_id) {
                    userInfo = userInfos[j];
                }
            }
            if (userInfo === null) {
                // ユーザー情報未登録の場合、常にpopする
                poped.push(threadSummaries.splice(i, 1)[0]);
                continue;
            }
            if (func(threadSummaries[i], userInfo) === true) {
                poped.push(threadSummaries.splice(i, 1)[0]);
            }
        }
        return poped;
    }

    private summaries2ThreadListItems = (summaries: any[]) => {
        if (summaries.length == 0) {
            return [];
        }

        let arr: JSX.Element[] = [];
        summaries.forEach((element: any) => {
            // console.log('ThreadList::summaries2ThreadListItems')
            // console.log(element)
            arr.push(
                <ThreadListItem
                    threadSummary={element}
                    key={element.thread_id.toString() + 'ThreadListItem'}
                    notify={this.props.notifyMessage}
                    onListItemClick={this.props.onListItemClick}
                >
                </ThreadListItem>
            )
        });
        return arr;
    }

    onSelectedDivisionUpdated = (selected: string[]) => {
        this.setState({
            selectedDivisions: selected
        })
    }

    render() {

        if (this.props.threadSummaries.length == 0) {
            return (
                <div></div>
            )
        }

        let listItems: JSX.Element[] = [];
        // To deep copy object
        let _threadSummaries: any = JSON.parse(JSON.stringify(this.props.threadSummaries));

        // Sort
        _threadSummaries.sort(
            (a: any, b: any) => {
                if (a.updated_at > b.updated_at) {
                    return 1;
                } else if (a.updated_at < b.updated_at) {
                    return -1;
                } else {
                    return 0;
                }
            }
        )

        // 部署フィルタ
        if (this.state.userInfos != null) {
            _threadSummaries = this.popThreadsByOwner(_threadSummaries, this.state.userInfos,
                (summary: any, userInfo: any) => {
                    // let owner_id = summary.owner_user_id;
                    let owner_division_ids: string[] = [];
                    for (var division of userInfo.divisions) {
                        owner_division_ids.push(division.division_id);
                    }
                    return isDuplicate(owner_division_ids, this.state.selectedDivisions);
                }
            )
        }

        let myThread = this.popMyThread(_threadSummaries);

        // let waitingForClose = this.popThreads(_threadSummaries,
        //     (summary:any) => {
        //         if(summary.has_user_status && summary.current_status === ThreadStatus.open){
        //             return true;
        //         }else{
        //             return false;
        //         }
        //     })

        let waitingForRegister = this.popThreads(_threadSummaries,
            (summary: any) => {
                if (!summary.has_user_status) {
                    return true;
                } else {
                    return false;
                }
            }
        )

        return (
            <div>
                <div>
                    <DivisionSelecter
                        onSelectedDivisionUpdated={this.onSelectedDivisionUpdated}
                    />
                </div>
                <div>
                    <div style={{ fontSize: '12px', marginTop: '5px' }}>
                        私の話部屋
                    </div>
                    <List>
                        {this.summaries2ThreadListItems(myThread)}
                    </List>
                </div>
                {/* <div>
                    <div>
                        上長による確認待ち
                    </div>
                    <List>
                        {this.summaries2ThreadListItems(waitingForClose)}
                    </List>
                </div> */}
                <div>
                    <div style={{ fontSize: '12px', marginTop: '5px' }}>
                        安否情報未登録の人の話部屋一覧
                    </div>
                    <List>
                        {this.summaries2ThreadListItems(waitingForRegister)}
                    </List>
                </div>
                <div>
                    <div style={{ fontSize: '12px', marginTop: '5px' }}>
                        安否情報登録済みの人の話部屋一覧
                    </div>
                    <List>
                        {this.summaries2ThreadListItems(_threadSummaries)}
                    </List>
                </div>
            </div>
        )
    }
}

