import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, Icon, AlertDialog, ProgressCircular, Page, Toolbar, BackButton, Button, Modal, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../icon/right-arrow1.svg';
import '../../App.css';

import { UserLoginStatus, AppController, App, AppCallbackType } from '../../App'
import { APIs, UserInfo, getParam } from '../../model/MarsAPIs'
import { jsxAttribute } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import '../../css/mars.css';
import { threadId } from 'worker_threads';

interface SequenceSelecterProps{
    onSequenceSelected:(sequence_id:number) => void;
    registerCallbackForUpdate?:(key: string, func: any) => void;
    withClosedSequenceCheckbox?:boolean;
    withClosedSequence?:boolean;
}

export class SequenceSelecter extends React.Component<SequenceSelecterProps, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            selectedIndex: '"-1"',
            activeSequences: [],
            selectedSequenceId: null,
            selectedSequenceInfo: null,
            withClosedSequence: this.props.withClosedSequence === true ? true : false
            // selectedSequenceThreadSummaries: []
        }
        this.updateSequences();
        if(this.props.registerCallbackForUpdate){
            this.props.registerCallbackForUpdate('__sequenceSelecter__', this.updateSequences);
        }
    }
    componentDidMount = () => {
        // this.updateSequences();
    }

    onSequenceChanged = (ev: any) => {
        this.selectSequence(ev.target.value);
    }

    selectSequence = (index: string) => {
        let _selectedIndex: number = Number(index.replace(/"/g, ''));
        if (this.state.activeSequences.length != 0) {
            this.setState({
                selectedIndex: index,
                selectedSequenceId: this.state.activeSequences[_selectedIndex].sequence_id,
                selectedSequenceInfo: this.state.activeSequences[_selectedIndex]
            })
        } else {
            this.setState({
                selectedIndex: '"0"',
                selectedSequenceId: null,
                selectedSequenceInfo: null
            })
        }
        this.props.onSequenceSelected(this.state.selectedSequenceId);
        // this.updateThreadSummaries(this.state.selectedSequenceId);
    }
    selectSequenceByScid = (scid:number) => {
        let activeSequences = this.state.activeSequences;
        for(let i=0; i<activeSequences.length; i++){
            let ac = activeSequences[i];
            if(ac.sequence_id == scid){
                this.selectSequence('"'+i+'"');
                return
            }
        }
        AppController.getInstance().notify('エラー!!：存在しないか完了済の安否登録番号: #' + scid);
    }
    updateSequences = () => {
        const status: string = this.state.withClosedSequence ? 'all' : 'open'
        APIs.getInstance().getSCSequenceInfo(status)
            .then(json => {
                this.setState({
                    activeSequences: json,
                })
                console.log('updateSequences: ' + json.length + ' seq found')
            })
            .then(() => {
                if(this.state.selectedIndex === '"-1"'){
                    let scidparam = getParam('scid');
                    if(scidparam !== null){
                        this.selectSequenceByScid(Number(scidparam));
                    }else{
                        this.selectSequence('"0"')
                    }
                }else{
                    this.selectSequence(this.state.selectedIndex);
                }
            })
            .catch(err => {
                console.log(typeof (err));
                AppController.getInstance().notify(err);
            })
    }
    handleCheckbox = (e: any) => {
        this.setState({ withClosedSequence: e.target.checked },
            () => {
                this.updateSequences();
                this.selectSequence('"0"')
            });
    }

    renderSelectClosedSeqCheckbox(){
        if(this.props.withClosedSequenceCheckbox){
            return(
                <div>
                    <input
                        id='__sequenceselecter--with-closed-checkbox__'
                        style={{ width: '18px', height: '18px', }}
                        type='checkbox'
                        // checked={this.state.withClosedSequence}
                        onChange={this.handleCheckbox}
                    ></input>
                    <label htmlFor='__sequenceselecter--with-closed-checkbox__'>完了含</label>
                </div>
            )
        }else{
            return(<div></div>)
        }
    }

    render = () => {

        if (this.state.activeSequences.length == 0) {
            return (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ height: '100%' }}>
                        現在オープン中の安否登録シーケンスはありません
                    </div>
                    {this.renderSelectClosedSeqCheckbox()}
                </div>
            )
        }

        let comment = '';
        try{
            comment = this.state.selectedSequenceInfo.comment
        }catch{
            comment = ''
        }

        return (
            // <Toolbar key='__SafetyConfirmation__Default__'>
            <div>
                <div className='center' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Select style={{ fontSize: '16px' }}
                        modifier="material"
                        // value={this.state.selected_value}
                        value={this.state.selectedIndex}
                        onChange={this.onSequenceChanged}
                        className='center'>
                        {
                            this.state.activeSequences.map((sc: any, index: number) => {
                                return (
                                    <option
                                        value={'"' + index + '"'}
                                        key={sc.sequence_id.toString()}
                                    >
                                        {'#' + sc.sequence_id + ' ' + sc.title}
                                    </option>
                                )
                            })
                        }
                    </Select>
                    {this.renderSelectClosedSeqCheckbox()}
                </div>
                <div className='sequence-selecter--comment--string'>{comment}</div>
            </div>
        );
    }
}