import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, ProgressCircular, Modal, Page, Toolbar, BackButton, Button, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../icon/right-arrow1.svg';
import '../../App.css';
import { DetailPageBase } from './PageBase'
import { APIs } from '../../model/MarsAPIs'
import { jsxAttribute } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

export class HelpPage extends DetailPageBase {
    public PageTitle: string = 'ヘルプページ'
    render() {
        return (
            <Page>
                <h1>
                    ヘルプページ
                </h1>
                <div>全ての画像は開発版です。</div>
                <hr></hr>
                <div>
                    <a
                        href={process.env.REACT_APP_HELP_PAGE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        利用者ガイド
                    </a>
                </div>
                <div>
                    <a
                        href={process.env.REACT_APP_API_GUIDE_PAGE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        開発者ガイド
                    </a>
                </div>

            </Page>
        )
    }
}