

export class PersonSafetyConst {
    public static PersonSafety: { [key: number]: string } = {
        0: '無事',
        1: 'ケガ（軽傷）',
        2: 'ケガ（重症・病院へ行く予定）'
    }
    public static PersonSafetySN: { [key: number]: string } = {
        0: '無事',
        1: '軽傷',
        2: '重症'
    }

    public static FamilySafety: { [key: number]: string } = {
        0: '全員無事',
        1: 'ケガ人あり（軽傷）',
        2: '怪我人あり（重症）',
        3: '確認中'
    }
    public static FamilySafetySN: { [key: number]: string } = {
        0: '無事',
        1: '軽傷',
        2: '重症',
        3: '確認中'
    }

    public static Location: { [key: number]: string } = {
        0: '押上',
        1: '現場',
        2: '自宅',
        3: 'その他'
    }
    public static LocationSN: { [key: number]: string } = {
        0: '押上',
        1: '現場',
        2: '自宅',
        3: 'その他'
    }

    public static DamageSituation: { [key: number]: string } = {
        0: 'なし',
        1: 'あり(別途下の入力欄で報告すること)',
        2: '不明'
    }
    public static DamageSituationSN: { [key: number]: string } = {
        0: 'なし',
        1: 'あり',
        2: '不明'
    }

    public static CoopCompanyWorkers: { [key: number]: string } = {
        0: 'いない',
        1: '全員無事',
        2: 'けが人あり(現時点で把握済みのけがの程度を別途下の入力欄で報告)',
        3: '確認中'
    }
    public static CoopCompanyWorkersSN: { [key: number]: string } = {
        0: 'いない',
        1: '無事',
        2: 'けが人あり',
        3: '確認中'
    }

    public static Workability: { [key: number]: string } = {
        0: '通常出社可能',
        1: '3H以内の遅れ',
        2: '3H以上の遅れ',
        3: '出社不可',
        4: '不明'
    }
    public static WorkabilitySN: { [key: number]: string } = {
        0: '通常',
        1: '3H以内',
        2: '3H以上',
        3: '不可',
        4: '不明'
    }
}

export enum MessageType {
    Message = 1,
    UserStatus = 2,
    Location = 4,
    Picture = 8
}

export enum ThreadStatus {
    open = 1,
    closed = 2
}

export class CycleColors {
    private colors: string[] = ['#ff0505', '#0505ff', '#05ff82', '#ffff05', '#ff8205'];
    private pointer: number;
    constructor() {
        this.pointer = -1;
    }
    getNext = (): string => {
        this.pointer++;
        if (this.pointer >= this.colors.length) {
            this.pointer = 0;
        }
        return this.colors[this.pointer];
    }
}

export const POLLING_INTERVAL_MILLIS: number = 5000;