import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, ProgressCircular, Modal, Page, Toolbar, BackButton, Button, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../icon/right-arrow1.svg';
import '../../App.css';
import { DetailPageBase } from './PageBase'
import { APIs } from '../../model/MarsAPIs'
import { jsxAttribute } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

export class EndSafetyConfirmationSequencePage extends DetailPageBase {
    public PageTitle: string = '安否確認終了'
    constructor(p: any, s: any) {
        super(p, s);
        Object.assign(
            this.state,
            {
                selected_value: null, // AvailableなもののIndex
                selected_sequence_id: null,
                availableSCs: [],
                comment: "",
                created_by_name: "",
                created_at: ""
            }
        )
        this.update();
    }

    updateSelectState = (value: String) => {
        // let val:String = ev.target.value;
        let val_n = Number(value.replace(/"/g, ''))
        this.setState({
            selected_value: value,
            selected_sequence_id: this.state.availableSCs[val_n].sequence_id,
            comment: this.state.availableSCs[val_n].comment,
            created_by_name: this.state.availableSCs[val_n].created_by_name,
            created_at: this.state.availableSCs[val_n].created_at,
            closeButtonDisabled: false
        })
    }
    clearSelectState = () => {
        this.setState({
            selected_value: null,
            selected_sequence_id: null,
            comment: null,
            created_by_name: null,
            created_at: null,
            closeButtonDisabled: true
        })
    }

    update = () => {
        APIs.getInstance().getSCSequenceInfo('open')
            .then(json => {
                console.log('Loaded sequences');
                // console.log(json)
                this.setState({
                    availableSCs: json
                })
                console.log('json length')
                console.log(json.length)
                if (this.state.availableSCs.length == 0) {
                    this.clearSelectState();
                } else {
                    this.updateSelectState('"0"');
                }
            })
            .catch(err => {
                this.props.notify('安否確認シーケンス情報の取得に失敗しました' + err.toString())
            })
    }

    onSelectSelected = (ev: any) => {
        this.updateSelectState(ev.target.value);
    }

    onCloseSCButtonClick = () => {
        APIs.getInstance().closeSCSequence(this.state.selected_sequence_id)
            .then(json => {
                if (json.status == 'OK') {
                    this.props.notify('安否登録シーケンス #' + json.sequence_id + ' をクローズしました');
                    this.update();
                } else {
                    throw Error('Error!' + JSON.stringify(json));
                }
            })
            .catch(err => {
                this.props.notify(err.toString());
            });
    }

    renderSelect = () => {
        // console.log('[DEBUG]renderSelect: ' + this.state.availableSCs);
        if (this.state.availableSCs.length == 0) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ fontSize: '1.1em' }}>現在オープン中のシーケンスはありません</div>
                </div>
            )
        } else {
            return (
                <Select style={{ width: '100%', fontSize: '16px' }}
                    modifier="material"
                    value={this.state.selected_value}
                    onChange={this.onSelectSelected}>
                    {
                        this.state.availableSCs.map((sc: any, index: number) => {
                            return (
                                <option
                                    value={'"' + index + '"'}
                                    key={sc.sequence_id.toString()}
                                >
                                    {'#' + sc.sequence_id + ' ' + sc.title}
                                </option>
                            )
                        })
                    }
                </Select>
            )
        }
    }

    render() {
        return (
            <Page>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '90%', marginTop: '1em' }}>
                        <div style={{ fontSize: '1.2em' }}>
                            安否確認シーケンスを終了します
                        </div>
                        <div style={{ fontSize: '0.7em' }}>
                            安否確認シーケンスをクローズすることで当該の安否確認シーケンスに対して誰もアクション出来なくなります
                        </div>

                        <div style={{ marginTop: '1.5em' }}>
                            {this.renderSelect()}
                        </div>

                        <div style={{ fontSize: '0.8em', marginTop: '1em', marginLeft: '1.5em' }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ color: '#999' }}>
                                            開始日時
                                        </td>
                                        <td>
                                            {this.state.created_at}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: '#999' }}>
                                            起票者
                                        </td>
                                        <td>
                                            {this.state.created_by_name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '5em', verticalAlign: 'top', color: '#999' }}>
                                            コメント
                                        </td>
                                        <td>
                                            {this.state.comment}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ marginTop: '3em' }}>
                                <Button
                                    modifier='material'
                                    onClick={this.onCloseSCButtonClick}
                                    disabled={this.state.closeButtonDisabled}
                                >
                                    安否確認シーケンスをクローズ
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
            </Page>
        )
    }
}

