import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, ProgressCircular, Modal, Page, Toolbar, BackButton, Button, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../icon/right-arrow1.svg';
import '../../App.css';
import { DetailPageBase } from './PageBase'
import { AppController } from '../../App'
import { APIs } from '../../model/MarsAPIs'
import { jsxAttribute } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

export class LogoutPage extends DetailPageBase {
    public PageTitle: string = 'サインアウト'
    constructor(p: any, s: any) {
        super(p, s);
    }
    onLogoutClick = (ev: any) => {
        //[TODO] Signout
        APIs.getInstance().ClearSession(
            ()=>{
                console.log('logout success')
            },
            ()=>{
                console.log('logout failed')
            }
        )
    } 
    render(){
        return(
            <Page>
                <div>
                    この端末での自動ログイン状態を解除します。
                </div>
                <Button
                    onClick={this.onLogoutClick}
                >
                    サインアウト
                </Button>
            </Page>
        )
    }
}