import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, ProgressCircular, Modal, Page, Toolbar, BackButton, Button, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../../icon/right-arrow1.svg';
import '../../App.css';
import { DetailPageBase } from './PageBase'
import { APIs } from '../../model/MarsAPIs'
import { jsxAttribute } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import { AppController } from '../../App';

export class NotificationSetting extends DetailPageBase{
    public PageTitle:string = '通知テスト';
    handleSendTestNotification = () => {
        APIs.getInstance().testNotification()
            .then(json_ => {
                AppController.getInstance().notify('サーバからの応答:'+json_.status);
            })
            .catch(err=>{
                AppController.getInstance().notify(err);
            })
    }
    render(){
        return(
            <Page>
                <h1>テスト通知を送る</h1>
                <div>ログインしているユーザーにテスト通知を送信します</div>
                <hr />
                <Button onClick={this.handleSendTestNotification}>
                    送信
                </Button>
            </Page>
        )
    }
}
export class SettingPage extends DetailPageBase{
    public PageTitle:string = '設定';
    constructor(p: any, s:any){
        super(p, s);
    }
    pushNotifyTestPage = () => {
        this.props.navigator.pushPage({
            component: NotificationSetting,
            title: 'NotificationSetting'
        });
    }

    render(){
        return(
            <Page>
                <List>
                    <ListItem>
                        <div className='left'>通知設定</div>
                    </ListItem>
                    <ListItem onClick={this.pushNotifyTestPage}>
                        <div className='left'>通知テスト</div>
                        {/* <Icon className='right' src>{icon_right_arrow}</Icon> */}
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                </List>
            </Page>
        )
    }
}

// export class SettingPageNavigator extends DetailPageBase{
//     public PageTitle: string = '設定'
//     constructor(p:any, s:any){
//         super(p, s);
//     }
//     renderPage(route: any, navigator: any) {
//         console.log('OtherPageNavigator::renderPage()')
//         // 現在のページのpropsがあればpropsを代入する。
//         const props = route.props || {};
//         Object.assign(props, this.props);
//         // Initialページ用にpropsをマージする
//         if(route._props){
//             Object.assign(props, route._props);
//         }
//         // 現在のnavigatorオブジェクトをprops.navigatorに代入する。
//         props.navigator = navigator;
//         // keyが無いとReactに怒られる為、routeオブジェクトに代入したtitleを一意の値として渡す。
//         props.key = route.title;
//         // createElementで仮想DOMを作成する。
//         return React.createElement(route.component, props);
//     }
    
//     render(){
//         return (
//             <Navigator
//                 renderPage={this.renderPage}
//                 // 初期ページのInitial値を代入
//                 initialRoute={
//                     {
//                         component: SettingPage,
//                         title: 'SettingPage'
//                     }
//                 }
//             />
//         );
//     }
// }