import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, ProgressCircular, Modal, Page, Toolbar, BackButton, Button, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../icon/right-arrow1.svg';
import './../App.css';
import './../css/cursor-tracker.css'
import { DetailPageBase } from './SubComponents/PageBase'
import { APIs } from '../model/MarsAPIs'
import { jsxAttribute, tsConstructorType } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

interface CursorTrackerState {
    enabled?: boolean;
    x?: number;
    y?: number;
    blinking?: boolean;
    touching?: boolean;
}

// export default class CursorTrackerPage extends Page{
//     constructor(p:any, s:any){
//         super(p);
//     }
// }

export default class CursorTracker extends React.Component<any, CursorTrackerState>{
    constructor(p: any) {
        super(p);
        const enabled: boolean = this.props.enabled === true ? true : false;
        this.state = {
            enabled: enabled,
            x: 0,
            y: 0,
            blinking: false,
            touching: false
        }
        if (enabled) {
            window.addEventListener('mousemove', this.handleMouseMove);
            window.addEventListener('touchmove', this.handleMouseMove);
            window.addEventListener('touchstart', this.handleTouchStart);
            window.addEventListener('touchend', this.handleTouchEnd);
        }
    }

    handleTouchStart = (event: any) => {
        this.setCursorPosition(event.touches);

        this.setState({
            touching: true,
            blinking: true
        })

        // 操作によってはtouchendイベントが飛ばないことがある
        //    →その後再度タップするとanimationイベントが開始されないのでanimationendイベントが飛ばない
        setTimeout(() => { this.setState({ blinking: false }) }, 1000)
    }
    handleTouchEnd = (event: any) => {
        console.log('touch end');
        this.setCursorPosition(event.touches);

        this.setState({
            touching: false
        })
    }
    handleAnimationEnd = (evnet: any) => {
        console.log('animation end')

        this.setState({
            blinking: false
        })
    }
    handleMouseMove = (event: any) => {
        this.setCursorPosition(event.touches);
    }
    setCursorPosition = (touches: any) => {
        try {
            this.setState({
                x: touches[0].pageX,
                y: touches[0].pageY
            })
        } catch{
            //nop
        }
    }
    render() {
        let cursor_state: string = '';

        if (this.state.blinking || this.state.touching) {
            cursor_state = '__cursor_tracker_circle_shown'
        }
        return (
            <Page>
                <div
                    className={'__cursor_tracker_circle' + ' ' + cursor_state}
                    onAnimationEnd={this.handleAnimationEnd}
                    style={{
                        top: this.state.y, left: this.state.x
                    }}
                >
                </div>
                {this.props.children}
            </Page>
        )
    }
}