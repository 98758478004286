import React from 'react';
import { BackButton, Card, Modal, Icon, Page, Tab, Tabbar, Toolbar, Toast, ToolbarButton, Button, Switch, Select, Input, Checkbox, ListItem, TabbarRenderTab, ProgressCircular } from "react-onsenui";
import logo from './logo.svg';
import './App.css';
import { APIs, UserInfo, getParam } from './model/MarsAPIs'
import LoginPage from './components/LoginPage'
import SafetyConfirmationPageNavigator from './components/SafetyConfirmationPage'
import SafetyConfirmationAggrigationPageNavigator from './components/SafetyConfirmationAggrigation'
import OtherPageNavigator from './components/OtherPage'
import CursorTracker from './components/CursorTracker'
import { jsxAttribute } from '@babel/types';
import earth_quake_icon from './icon/earth-quake.svg'
import './css/mars.css'
import './css/csshake-shake-horizontal.css'
import './css/csshake.css'
// import { jsxElement } from '@babel/types';

// const ClassNames = require('./App.css')


// class SwitcherButton extends React.Component<SwitcherButtonConfig>{
//   // constructor(props: SwitcherButtonConfig){
//   //   // super(props);
//   //   // this.props = props;
//   // }
//   render(){
//     return(
//       <div>this.prop.name</div>
//     )
//   };
// }


// class ControlBarButton extends React.Component<any>{
//   render(){
//     return(
//       <li onClick={this.props.handle}>{this.props.name}</li>
//     );
//   };
// }

// class ControlButtonArgs{
//   name: string;
//   handle: any;
//   constructor(name: string, handle: any){
//     this.name = name;
//     this.handle = handle;
//   }
// }

// interface ControlBarArgs{
//   buttonDef: ControlButtonArgs[]
// }

// class ControlBar extends React.Component<ControlBarArgs[]>{
//   constructor(props: ControlBarArgs[]) {
//     super(props);
//     this.state = {
//       selected: '',
//     };
//   }
//   render(){
//     console.log(typeof this.props)
//     let l: JSX.Element[] = [];
//     for(let i in this.props.buttonDef){
//       // console.log('element' + element);
//       l.push(<ControlBarButton name={this.props.buttonDef.name} handle={this.props.handle}/>)
//       // l.push(<li>e</li>);
//     };
//     return(
//       l
//       //<SwitcherButton name="test1" />
//     );
//   };
// }

// class MainFrame extends React.Component{
//   constructor(props:any){
//     super(props);
//     this.handleAppChange = this.handleAppChange.bind(this);
//   }
//   handleAppChange(name: string){
//     console.log('clicked:' + name)
//   }
//   render(){
//     let buttonargs: ControlButtonArgs[] = [
//       new ControlButtonArgs('防災情報', this.handleAppChange),
//       new ControlButtonArgs('安否確認', this.handleAppChange),
//       new ControlButtonArgs('管理', this.handleAppChange),
//     ]
//     return(
//       <ControlBar buttonDef={buttonargs}/>
//       // Application
//     );
//   };
// }

// class MainToolbar extends React.Component<string>{
//   constructor
// }

interface Employee {
    name: string
    division: string
    email: string
    phone: string
}

interface ProjectInfo {
    sasizu_num: string
    sasizu_name: string
    address: string
    eigyo_tantou: Employee
    genba_tantou: Employee
    alerts: string[]
    warnings: string[]
}

interface ProjectCardData {
    data: ProjectInfo
}

class DisasterPreventionInfoCard extends React.Component<ProjectCardData, any>{
    onCardClick = (ev: any) => {
        console.log(ev);
        console.log(this.props);
    }
    render() {
        const slim = { height: "18px", marginTop: "0px", fontSize: "15px" };
        const red = { height: "18px", color: "red", fontSize: "15px" };
        const yellow = { height: "18px", color: "yellow", fontSize: "15px" };
        const small = { height: "10px", fontSize: "8px" };
        return (
            <div onClick={this.onCardClick}>
                <Card>
                    <div>
                        <div style={slim}>{this.props.data.sasizu_num} | {this.props.data.sasizu_name}<br /></div>
                        <div style={slim}>{this.props.data.address} </div>
                        <div style={red}>警報：{this.props.data.alerts.join("|")}<br /></div>
                        <div style={yellow}>注意報：{this.props.data.warnings.join("|")}<br /></div>
                        <div style={slim}>現場担当：{this.props.data.genba_tantou.name} | {this.props.data.genba_tantou.phone}<br /></div>
                        <div style={slim}>営業担当：{this.props.data.eigyo_tantou.name} | {this.props.data.eigyo_tantou.phone}<br /></div>
                        <div style={small}>*このカードのレイアウトはまだサンプルイメージ</div>
                    </div>
                </Card>
            </div>
        )
    }
}

interface DivisionInfo {
    name: string,
    shortName: string
}

// 防災情報表示ページ
class DisasterPreventionPage extends React.Component<any, any>{
    private divisions: DivisionInfo[]
    constructor(p: any, s: any) {
        super(p, s);

        //TODO get through API
        this.divisions = [
            {
                name: "koujibu",
                shortName: "工"
            },
            {
                name: "jiosaiennsubu",
                shortName: "ジ"
            },
            {
                name: "gijutubu",
                shortName: "技"
            },
            {
                name: "PCBbu",
                shortName: "P"
            }
        ]
        let checked: { [key: string]: boolean; } = {};
        // let checked:{string:boolean};
        for (var i in this.divisions) {
            checked[this.divisions[i].name] = true;
        }
        this.state = {
            checked: checked
        }
    }

    toolbarCheckboxOnChange = (ev: any) => {
        console.log(ev);
    }

    getTestData = (): ProjectInfo[] => {
        return [
            {
                sasizu_num: "EK1234567890",
                sasizu_name: "テスト指図１",
                address: "東京都墨田区押上1−1−2",
                eigyo_tantou: {
                    name: "山田太郎",
                    division: "テスト部",
                    email: "tarou@geotechnos.co.jp",
                    phone: "090-1234-5678"
                },
                genba_tantou: {
                    name: "山田花子",
                    division: "テスト部",
                    email: "hanako@geotechnos.co.jp",
                    phone: "090-8765-4321"
                },
                alerts: ["暴風", "大雨"],
                warnings: ["濃霧", "乾燥"]
            },
            {
                sasizu_num: "EK2345678901",
                sasizu_name: "テスト指図2",
                address: "大阪府●●",
                eigyo_tantou: {
                    name: "鈴木太郎",
                    division: "テスト部",
                    email: "tarou2@geotechnos.co.jp",
                    phone: "090-1234-5678"
                },
                genba_tantou: {
                    name: "鈴木花子",
                    division: "テスト２部",
                    email: "hanako2@geotechnos.co.jp",
                    phone: "090-8765-4321"
                },
                alerts: ["暴風"],
                warnings: ["濃霧"]
            },
            {
                sasizu_num: "EK3456789012",
                sasizu_name: "テスト指図3",
                address: "千葉県●●",
                eigyo_tantou: {
                    name: "鈴木太郎",
                    division: "テスト部",
                    email: "tarou2@geotechnos.co.jp",
                    phone: "090-1234-5678"
                },
                genba_tantou: {
                    name: "鈴木花子",
                    division: "テスト２部",
                    email: "hanako2@geotechnos.co.jp",
                    phone: "090-8765-4321"
                },
                alerts: ["暴風"],
                warnings: ["濃霧"]
            }
        ]
    }
    renderToolbar = () => {
        // TODO
        // Get data via API

        let division_switches: JSX.Element[] = new Array();
        for (var i in this.divisions) {
            let shortname = this.divisions[i].shortName;
            let divname = this.divisions[i].name;
            division_switches.push(
                <ListItem key={shortname}>
                    <Checkbox checked={this.state.checked[divname]}
                        onChange={this.toolbarCheckboxOnChange}
                        value={this.divisions[i].name}
                        inputId={this.divisions[i].name}>
                    </Checkbox>
                    <a>{shortname}</a>
                </ListItem>
            )
        }
        return (
            <Toolbar>
                {/* <div>工　ジオ　技　P</div> */}
                {/* <div className="center"> */}
                {division_switches}
                {/* </div> */}
            </Toolbar>
        )
    }
    render() {
        let infocards: JSX.Element[] = new Array();
        // infocards = new Array();
        let data = this.getTestData();
        for (var i in data) {
            infocards.push(
                <DisasterPreventionInfoCard data={data[i]} key={data[i].sasizu_num} />
            )
        }
        return (
            // <Page renderToolbar={this.renderToolbar}>
            <Page>
                {infocards}
                <div style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", opacity: 0.5, backgroundColor: 'black', display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column' }}>
                    <div style={{ color: 'white', fontSize: '3em' }}>UNVEILS ON</div>
                    <div style={{ color: 'white', fontSize: '3em' }}>Q1 2020</div>
                </div>
            </Page>
        )
    }
}

// // 安否登録表示ページ
// class SafetyConfirmationRegisterPage extends React.Component<any, any>{
//   constructor(p: any) {
//     super(p);
//     this.state = {
//       value: "safe",
//       text: "",
//     };

//   }
//   render() {
//     console.log(this.state);
//     return (
//       <Page>
//         安否登録：シーケンス番号002 ●●地震
//         <div>
//           <Select modifier="underbar"
//             value={this.state.value}
//             onChange={(event) => this.setState({ value: event.target.value })}>
//             <option value="safe">無事です</option>
//             <option value="unsafe-1">けがをしました(軽微)</option>
//             <option value="unsafe-2">けがをしました(中程度)</option>
//             <option value="unsafe-3">けがをしました(重篤)</option>
//           </Select>
//         </div>
//         <div style={{ marginTop: "30px" }}>
//           <Input
//             value={this.state.text} float
//             onChange={(event) => { this.setState({ text: event.target.value }) }}
//             modifier='material'
//             placeholder='コメント' />
//         </div>

//         <ListItem>
//           <div className="center">GPS情報を付加する</div>
//           <div className="right"><Switch></Switch></div>
//         </ListItem>

//         <div>写真</div>
//         <div>
//           <a style={{ marginRight: "10px" }}><Button>撮る</Button></a>
//           <Button>カメラロールから選ぶ</Button>
//         </div>
//         --------------------------------------
//         <div className="right">
//           <Button>登録</Button>
//         </div>

//       </Page>
//     )
//   }
// }

// // 安否確認表示ページ
// class SafetyConfirmationThreadPage extends React.Component<any, any>{
//   render() {
//     return (
//       <Page>
//         <div>シーケンス番号：002 ●●地震</div>
//         <div>集計状況</div>
//         <div style={{ width: "80%", display: "flex", margin: "0 auto" }}>
//           <div style={{ color: "red" }}>未登録：50％</div>
//           <div style={{ color: "blue" }}>対応中：20％</div>
//           <div style={{ color: "green" }}>クローズ済：30％</div>
//         </div>
//         <div style={{ width: "80%", height: "20px", display: "flex", margin: "0 auto" }}>
//           <div style={{ width: "50%", backgroundColor: "red" }}></div>
//           <div style={{ width: "20%", backgroundColor: "blue" }}></div>
//           <div style={{ width: "30%", backgroundColor: "green" }}></div>
//         </div>
//         <div>
//           ----------------------------------------
//         </div>
//         <div style={{ fontSize: "10px" }}>
//           部署等でフィルタするためのスイッチ類がここに入る
//         </div>
//         <div>
//           ----------------------------------------
//         </div>
//         <Card>
//           <div>
//             <div>鈴木太郎 技術部 090-1234-5678</div>
//             <div>ステータス：<a style={{ color: "red" }}>(未登録)</a></div>
//           </div>
//         </Card>
//         <Card>
//           <div>
//             <div>山田一郎 工事部 090-1234-5678</div>
//             <div>ステータス：<a style={{ color: "blue" }}>安否回答済(対応中)</a></div>
//             <div style={{ fontSize: "8px" }}>山田一郎 12:58</div>
//             <div style={{ borderRadius: "0px 25px 25px 25px", marginBottom: "10px", borderWidth: "2px", backgroundColor: "royalblue", color: "black" }}>

//               <div style={{ marginLeft: "20px" }}>けが等ありません。現在、出張中で〇●にいます。
//               </div>
//               <div>GPSデータあり：</div>
//               <div style={{ marginLeft: "20px", width: "11.5em", color: "blue", textDecoration: "underline" }}>GoogleMapで開く</div>

//             </div>
//             <div>
//               <a style={{ marginLeft: "10px" }}><Button>返信</Button></a>
//               <a style={{ marginLeft: "10px" }}><Button>クローズする</Button></a>
//             </div>
//           </div>
//         </Card>
//         <Card>
//           <div>
//             <div>佐藤次郎 工事部 090-1234-5678</div>
//             <div>ステータス：<a style={{ color: "green" }}>安否確認完了</a></div>
//             <div style={{ fontSize: "8px" }}>佐藤次郎 12:56</div>
//             <div style={{ borderRadius: "0px 25px 25px 25px", marginBottom: "10px", borderWidth: "2px", backgroundColor: "royalblue", color: "black" }}>

//               <div style={{ marginLeft: "20px" }}>骨折かもしれません。写真のように敷地内の土地が陥没しています。作業員は全員●●小学校へ避難しました
//                 <div>
//                   <a><img src="testpic1.jpg" width="50px" height="50px"></img></a>
//                   <a><img src="testpic2.jpg" width="50px" height="50px"></img></a>
//                   <a><img src="testpic3.jpg" width="50px" height="50px"></img></a>
//                   <a><img src="testpic4.jpg" width="50px" height="50px"></img></a>
//                 </div>
//               </div>
//             </div>
//             <div style={{ fontSize: "8px" }}>上司三郎 13:05</div>
//             <div style={{ borderRadius: "0px 25px 25px 25px", marginBottom: "10px", borderWidth: "2px", backgroundColor: "royalblue", color: "black" }}>

//               <div style={{ marginLeft: "20px" }}>
//                 陥没周囲にカラーコーンを置き、安全を確認次第、●●病院へ行き診察を受けてください
//               </div>
//             </div>
//             <div style={{ fontSize: "8px" }}>佐藤次郎 14:01</div>
//             <div style={{ borderRadius: "0px 25px 25px 25px", marginBottom: "10px", borderWidth: "2px", backgroundColor: "royalblue", color: "black" }}>
//               <div style={{ marginLeft: "20px" }}>
//                 病院で診察を受けましたが軽い捻挫とのこと。
//               </div>
//             </div>
//             <div style={{ fontSize: "8px" }}>システムメッセージ 14:22</div>
//             <div style={{ borderRadius: "0px 25px 25px 25px", marginBottom: "10px", borderWidth: "2px", backgroundColor: "green", color: "black" }}>
//               <div>上司三郎さんがクローズしました</div>
//             </div>

//             <div>
//               <a style={{ marginLeft: "10px" }}><Button disabled={true}>返信</Button></a>
//               <a style={{ marginLeft: "10px" }}><Button disabled={true}>クローズする</Button></a>
//               <a style={{ marginLeft: "10px" }}><Button>オープンする</Button></a>
//             </div>
//           </div>
//         </Card>
//       </Page>
//     )
//   }
// }

// class SafetyConfirmationAggrigationPage extends React.Component<any, any>{
//     render() {
//         return (
//             <Page><div>Aggr</div></Page>
//         )
//     }
// }


// class TabPage extends React.Component<any, any> {
//   render() {
//     return (
//       <Page>
//         <div>
//           This is the <strong>{this.props.title}</strong> page!
//           ?????
//         </div>
//       </Page>
//     );
//   }
// }

// class MyTab extends Tab {
//   constructor(p:any, s:any){
//     super(p,s)
//   }
// }




// interface UserLoginStatusState {
//     ready?: boolean
//     userInfo?: UserInfo | null
// }

class LoadingUserInfoPage extends React.Component {
    render() {
        return (
            <Page>
                <div style={{ display: 'flex', width: '100%', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                        <ProgressCircular
                            indeterminate
                        >
                        </ProgressCircular>
                    </div>
                    <div>
                        読み込み中...
                    </div>
                </div>
            </Page>
        )
    }
}

export class UserLoginStatus extends React.Component<any>{
    constructor(p: any) {
        super(p);
        // this.state = {
        //     ready: false,
        //     userInfo: null
        // };
        console.log('UserLoginStatus::constructor')
    }

    render() {
        console.log('login status rendered')
        // console.log('this.state.ready=' + this.state.ready)
        // console.log(APIs.getInstance().myInfo)
        // if (!this.state.ready) {
        //     // APIs.getInstance().onMyInfoResolved(this.onUserInfoResolved);
        //     return (<div className='right'><ProgressCircular indeterminate></ProgressCircular></div>);
        // }
        const userInfo = AppController.getInstance().getLoginUserInfo();
        return (
            <div className='right'>
                <div className='userloginstatus-wrapper'>
                    <img 
                        src={earth_quake_icon} 
                        className='earth-quake-icon-toolbar shake-horizontal-slow'
                    ></img>
                    {userInfo.last_name}
                </div>
            </div>
        );

    }
}

export enum AppCallbackType {
    PageActivated,
    PageDeactivated
}

export class AppController {
    private static instance: AppController | null = null;
    private notifyFunc: any = null;
    private forceRerenderFunc: any = null;
    private registerAppCallbackFunc: any = null;
    private registerToolbarRendererFunc: any = null;
    private applyToolbarRendererNowFunc: any = null;
    private getCurrentToolbarRendererFunc: any = null;
    private getLoginUserInfoFunc: any = null;
    private setPageHeightPxFunc: any = null;
    private clearPageHeightFunc: any = null;
    static getInstance(): AppController {
        console.log('APIs.getInstance');
        if (!this.instance) {
            console.log('call constructor');
            this.instance = new AppController();
        }
        return this.instance;
    }
    public registerNotify = (func: any) => {
        this.notifyFunc = func;
    }
    public notify = (message: any) => {
        this.notifyFunc(message);
    }
    public registerForceRerender = (func: any) => {
        this.forceRerenderFunc = func;
    }
    public forceRerender = () => {
        this.forceRerenderFunc();
    }
    public registerRegisterAppCallback = (func: any) => {
        this.registerAppCallbackFunc = func;
    }
    public registerAppCallback = (type: AppCallbackType, index: number, func: any) => {
        this.registerAppCallbackFunc(type, index, func);
    }
    public registerRegisterToolbarRenderer = (func: any) => {
        this.registerToolbarRendererFunc = func;
    }
    public registerToolbarRenderer = (index: number, func: any) => {
        this.registerToolbarRendererFunc(index, func);
    }
    public registerApplyToolbarRendererNow = (func: any) => {
        this.applyToolbarRendererNowFunc = func
    }
    public applyToolbarRendererNow = () => {
        this.applyToolbarRendererNowFunc();
    }
    public registerGetCurrentToolbarRenderer = (func: any) => {
        this.getCurrentToolbarRendererFunc = func;
    }
    public getCurrentToolbarRenderer = () => {
        return this.getCurrentToolbarRendererFunc();
    }
    public registerGetLoginUserInfo = (func: any) => {
        this.getLoginUserInfoFunc = func;
    }
    public getLoginUserInfo = () => {
        return this.getLoginUserInfoFunc();
    }
    // public registerSetPageHeightPx = (func: any) => {
    //     this.setPageHeightPxFunc = func;
    // }
    // public setPageHeightPx = (heightPx: any) => {
    //     this.setPageHeightPxFunc(heightPx);
    // }
    // public registerClearPageHeight = (func: any) => {
    //     this.clearPageHeightFunc = func;
    // }
    // public clearPageHeight = () => {
    //     this.clearPageHeightFunc();
    // }
}

interface AppStates {
    index: number
    loggedIn: boolean
    // login_user_name: string | null
    loading_login_user_info: boolean,
    login_user_info: any | null,
    toolbarRenderer: () => JSX.Element | null
    // toolbarUpdatedTime: String
    isToastShown: boolean
    toastMessage: String
    vdomLastUpdated: String
    height: String
}

export class App extends React.Component<{}, AppStates> {
    private tabpagenames: string[];
    private tabpageelements: JSX.Element[];
    private defaultToolbarRenderer: () => JSX.Element;
    private toolbarRenderers: { [key: number]: any } = {};
    private callbacks: any;
    // private static instance:App|null = null;
    // static getInstance(): App {
    //     if (!this.instance) {
    //         console.log('call constructor');
    //         this.instance = new App({});
    //     }
    //     return this.instance;
    // }
    constructor(p: {}) {
        super(p);
        console.log(process.env);

        this.defaultToolbarRenderer = () => {
            return (
                <Toolbar key='__App__DefaultToolbar__'>
                    <div></div>
                    <div className="center">{this.tabpagenames[this.state.index]}</div>
                    <div className='right' key='__App__DefaultToolbar__UserLoginStatus'><UserLoginStatus key='uniqu'></UserLoginStatus></div>
                </Toolbar>
            );
        }

        this.state = {
            index: this.getIndexFromUrl(),
            loggedIn: false,
            // login_user_name: "(Loading)",
            loading_login_user_info: true,
            login_user_info: null,
            toolbarRenderer: this.defaultToolbarRenderer,
            // toolbarUpdatedTime: (new Date).toLocaleTimeString(),
            isToastShown: false,
            toastMessage: '',
            vdomLastUpdated: (new Date).toLocaleTimeString(),
            height: ''
        }
        // this.toolbarRenderer = this.defaultToolbarRenderer;

        this.callbacks = {
            pageActivated: {},
            pageDeactivated: {}
        }

        this.tabpagenames = [
            '防災情報',
            // '安否登録',
            '安否確認',
            '安否集約',
            'その他'
        ];
        this.tabpageelements = [
            <DisasterPreventionPage key={this.tabpagenames[0]} index={0} notify={this.notifyMessage} />,
            // <SafetyConfirmationRegisterPage key={this.tabpagenames[1]} notify={this.notifyMessage}/>,
            <SafetyConfirmationPageNavigator
                key={this.tabpagenames[1]}
                index={1}
                registerAppCallback={this.registerAppCallback}
                registerToolbarRenderer={this.registerToolbarRenderer}
                applyToolbarRendererNow={this.applyToolbarRendererNow}
                getCurrentToolbarRenderer={this.getCurrentToolbarRenderer}
                notify={this.notifyMessage}
            />,
            <SafetyConfirmationAggrigationPageNavigator
                key={this.tabpagenames[2]}
                index={2}
                notify={this.notifyMessage}
            />,
            <OtherPageNavigator
                key={this.tabpagenames[3]}
                index={3}
                registerToolbarRenderer={this.registerToolbarRenderer}
                applyToolbarRendererNow={this.applyToolbarRendererNow}
                getCurrentToolbarRenderer={this.getCurrentToolbarRenderer}
                notify={this.notifyMessage}
            />
        ]
        AppController.getInstance().registerNotify(this.notifyMessage);
        AppController.getInstance().registerForceRerender(this.forceRerender);
        AppController.getInstance().registerRegisterAppCallback(this.registerAppCallback);
        AppController.getInstance().registerRegisterToolbarRenderer(this.registerToolbarRenderer);
        AppController.getInstance().registerApplyToolbarRendererNow(this.applyToolbarRendererNow);
        AppController.getInstance().registerGetCurrentToolbarRenderer(this.getCurrentToolbarRenderer);
        AppController.getInstance().registerGetLoginUserInfo(this.getLoginUserInfo);

        APIs.getInstance().setHandle401LoginRequired(() => {
            this.setState({
                loggedIn: false
            })
        })

    };

    getLoginUserInfo = () => {
        return this.state.login_user_info;
    }

    componentDidMount = () => {
        console.log('trying to check token')
        APIs.getInstance().tryCheckToken(
            () => {
                console.log('App::componentDidMount>> successcallback')
                this.setState({
                    loggedIn: true,
                    loading_login_user_info: true
                },
                    () => {
                        APIs.getInstance().getMyInfo()
                            .then(json => {
                                this.setState({
                                    loading_login_user_info: false,
                                    login_user_info: json
                                })
                            })
                    })
            },
            () => {
                console.log('App::componentDidMount>> failurecallback')
                this.setState({
                    loggedIn: false,
                    loading_login_user_info: false
                })
            }
        )
    }

    forceRerender = () => {
        this.setState({
            vdomLastUpdated: (new Date).toLocaleTimeString()
        })
    }

    registerToolbarRenderer = (index: number, func: any) => {
        this.toolbarRenderers[index] = func;
        console.log('[DEBUG] toolbarRenderer registerd: ' + index)
    }

    getCurrentToolbarRenderer = (): any => {
        return this.state.toolbarRenderer
    }

    applyToolbarRendererNow = () => {
        this.setState({
            toolbarRenderer: this.toolbarRenderers[this.state.index]
        })
    }

    registerAppCallback = (type: AppCallbackType, index: number, func: any) => {
        let assign: { [key: number]: any; } = {};
        assign[index] = func;
        let assigned: any;
        if (type == AppCallbackType.PageActivated) {
            assigned = this.callbacks.pageActivated;
        } else if (type == AppCallbackType.PageDeactivated) {
            assigned = this.callbacks.pageDeactivated;
        }
        Object.assign(
            assigned,
            assign
        )
        console.log('Callback registerd!!')
        console.log(this.callbacks)
    }

    onTabbarPreChange = (ev: any) => {
        console.log('ontabbarprechaneg')
        console.log(ev)

        // 登録済みのツールバーレンダラがあれば復帰させる. なければデフォルト
        if (ev.index in this.toolbarRenderers) {
            console.log('[DEBUG] restore toolbar renderer: ' + ev.index);
            this.setState({
                toolbarRenderer: this.toolbarRenderers[ev.index]
            })
        } else {
            console.log('[DEBUG] restore toolbar renderer as default: ' + ev.index);
            this.setState({
                toolbarRenderer: this.defaultToolbarRenderer
            })
        }

        // When page activated
        if (ev.index in this.callbacks.pageActivated) {
            this.callbacks.pageActivated[ev.index]();
        }

        // When page deactivated
        if (this.state.index in this.callbacks.pageDeactivated) {
            this.callbacks.pageDeactivated[this.state.index]();
        }

        this.setState({ index: ev.index })
    }

    forceUpdate = () => {
        this.setState({
            vdomLastUpdated: (new Date).toLocaleTimeString()
        })
    }

    notifyMessage = (message: any) => {
        console.log('App::notifyMessage')
        console.log(message);
        let messageStr: String;
        if (message == null) {
            messageStr = 'ふめいなえらー';
        } else if (typeof message == 'object') {
            if ('toString' in message) {
                messageStr = message.toString();
            } else {
                messageStr = 'ふめいなえらー'
            }
        } else {
            messageStr = message.toString();
        }

        this.setState({
            isToastShown: true,
            toastMessage: messageStr
        })
    }

    onToastPostShow = () => {
        setTimeout(() => {
            this.setState({
                isToastShown: false,
                toastMessage: ""
            })
        }, 4000)
    }

    renderTabs = () => {

        let tabPages: TabbarRenderTab[] = [];
        for (var i in this.tabpagenames) {
            tabPages.push({
                content: this.tabpageelements[i],
                tab: <Tab key={this.tabpagenames[i]} label={this.tabpagenames[i]} />
            })
        }
        return tabPages;
    }


    onLogin = () => {
        this.setState({
            loggedIn: true,
            loading_login_user_info: true
        });
        console.log('onLogin')
        setTimeout(() => {
            APIs.getInstance().getMyInfo()
                .then(json => {
                    this.setState({
                        loading_login_user_info: false,
                        login_user_info: json
                    })
                })
        }, 500)
    }
    onLoginUserInfoResolved = (user_info: UserInfo) => {
        this.setState({
            login_user_info: user_info
        })
    }

    logout = () => {
        console.log('logout');
    }

    // [TODO] URLから初期表示ページを取得
    getIndexFromUrl = (): number => {
        let pageid: string | null = getParam('pageid');
        if (pageid === null) {
            return 1;
        } else {
            return parseInt(pageid);
        }

    }

    render() {

        if (this.state.loading_login_user_info) {
            return <LoadingUserInfoPage />
        } else if (!this.state.loggedIn) {
            return <LoginPage onLoginComplete={this.onLogin}></LoginPage>
            // }else if(this.state.loading_login_user_info){
            //     return <LoadingUserInfoPage />
        } else if (this.state.login_user_info !== null) {
            // APIs.getInstance().onMyInfoResolved(this.onLoginUserResolved)
            console.log('my name')
            // console.log(APIs.getInstance().myInfo)
            return (
                // Mainpage
                <Page
                    // renderToolbar={this.toolbarRenderer}
                    renderToolbar={this.state.toolbarRenderer}
                >
                    {/* <CursorTracker /> */}
                    <Toast
                        style={{ zIndex: 2147483647, translate: 'none !important' }}
                        isOpen={this.state.isToastShown}
                        animation='default'
                        animationOptions={{ duration: 4, delay: 4 }}
                        onPostShow={this.onToastPostShow}
                    >
                        <div>{this.state.toastMessage}</div>
                    </Toast>
                    <Tabbar
                        // style={{ height: this.state.height + 'px' }}
                        // style={{ height: '200px' }}
                        id='Tabbar'
                        index={this.state.index}
                        renderTabs={this.renderTabs}
                        onPreChange={this.onTabbarPreChange}
                    />
                </Page>
            )
        } else {
            return (
                <Page>
                    <div>No match IF</div>
                    {/* <div>API.isLoggedIn={APIs.getInstance().isLoggedIn}</div> */}
                    <div>this.state.loading_login_user_info={this.state.loading_login_user_info}</div>
                </Page>
            )
        }
    };
};

export default App;
