import React from 'react';
import { ChangeEvent } from 'react'
import { Navigator, ProgressCircular, Modal, Page, Toolbar, BackButton, Button, Select, Input, Dialog, Toast, Checkbox, List, ListItem, TabbarRenderTab } from "react-onsenui";
import logo from '../logo.svg';
import icon_right_arrow from '../../icon/right-arrow1.svg';
import '../../App.css';
import '../../css/mars.css'
import { DetailPageBase } from './PageBase'
import { APIs } from '../../model/MarsAPIs'
import { jsxAttribute } from '@babel/types';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import { AppController } from '../../App';
import { UserAccountInfoPage } from './UserAccountInfoPage'

class KickUserPage extends DetailPageBase {
    public PageTitle: string = 'ログイン状態強制解除';
    constructor(p: any, s: any) {
        super(p, s);
        this.state = {
            userId: ''
        }
    }
    handleClick = () => {
        APIs.getInstance().deleteUserTokens(this.state.userId)
            .then(json_ => {
                AppController.getInstance().notify('Response from server: ' + json_.status);
            })
            .catch(error => {
                AppController.getInstance().notify(error);
            })
    }
    render() {
        return (
            <Page>
                対象者のユーザーID：
                <input
                    onChange={(ev) => this.setState({ userId: ev.target.value })}
                    value={this.state.userId}
                ></input>

                <button
                    onClick={this.handleClick}
                >
                    解除
                </button>
            </Page>
        )
    }
}

class UserListRow extends React.Component<any>{
    constructor(props: any) {
        super(props);
    }

    handleClick = (ev: any) => {
        this.props.navigator.pushPage({
            component: UserAccountInfoPage,
            title: 'UserAccountInfoPage',
            _props: {
                userInfo: this.props.userInfo,
                kickable: true,
                deletable: true
            }
        })
    }

    render() {

        let division_names = this.props.userInfo.divisions.map((division: any) => {
            return (<div>{division.division_id + '(' + division.division_name + ')'}</div>)
        })

        return (
            <tr onClick={this.handleClick} key={'UserListRow-tr-' + this.props.userInfo.user_id}>
                <td>
                    {this.props.userInfo.user_id}
                </td>
                <td>
                    {this.props.userInfo.first_name}
                </td>
                <td>
                    {this.props.userInfo.first_name_kana}
                </td>
                <td>
                    {this.props.userInfo.last_name}
                </td>
                <td>
                    {this.props.userInfo.last_name_kana}
                </td>
                <td>
                    {this.props.userInfo.email_address}
                </td>
                <td>
                    {this.props.userInfo.phone_number}
                </td>
                <td>
                    {this.props.userInfo.permission_level}
                </td>
                <td>
                    {division_names}
                </td>
            </tr>
        )
    }
}

class UserListPage extends DetailPageBase {
    public PageTitle: string = 'ユーザー一覧';
    constructor(props: any, state: any) {
        super(props, state);
        this.state = {
            usersInfo: null,
            loading: true
        }
    }
    componentDidMount = () => {
        APIs.getInstance().getUsers([])
            .then(users_info => {
                this.setState({
                    usersInfo: users_info,
                    loading: false
                })
            })
    }

    render() {
        if (this.state.loading) {
            return (<Page><div><ProgressCircular indeterminate></ProgressCircular></div></Page>)
        }

        let records = this.state.usersInfo.map((info: any) => {
            return (<UserListRow userInfo={info} navigator={this.props.navigator} />)
        })

        return (
            <Page>
                <table className="tlayout3">
                    <tbody>
                        <tr key='UserListPage-table-tbody-tr'>
                            <th>user_id</th>
                            <th>first_name</th>
                            <th>first_name_kana</th>
                            <th>last_name</th>
                            <th>last_name_kana</th>
                            <th>email_address</th>
                            <th>phone_number</th>
                            <th>permission_level</th>
                            <th>divisions</th>
                        </tr>
                        {records}
                    </tbody>
                </table>
            </Page>
        )

    }

}

class NotificationLogList extends React.Component<any>{
    constructor(props: any) {
        super(props);
    }
    render() {

        if (this.props.notificationLog === undefined) {
            return <div></div>
        }

        if (this.props.notificationLog === null) {
            return <div></div>
        }

        if (this.props.notificationLog.length == 0) {
            return <div>該当するデータはありません</div>
        }

        let records: JSX.Element[] = [];
        for (var i = 0; i < this.props.notificationLog.length; ++i) {
            const e = this.props.notificationLog[i];
            let style = {};
            if (e.status !== 'SUCCESS') {
                style = { backgroundColor: 'red' };
            }
            records.push(
                <tr style={style}>
                    <td>{e.id_to}</td>
                    {/* <td>{e.got_from_queue_at}</td> */}
                    <td>{e.method}</td>
                    <td>{e.title}</td>
                    <td>{e.destination}</td>
                    <td>{e.status}</td>
                    <td>{e.error_detail}</td>
                    <td>{e.finished_at}</td>
                </tr>
            )
        }

        return (
            <table>
                <tr style={{ backgroundColor: 'gray' }}>
                    <th>TO</th>
                    {/* <th>got_from_queue_at</th> */}
                    <th>method</th>
                    <th>title</th>
                    <th>destination</th>
                    <th>status</th>
                    <th>error_detail</th>
                    <th>finished_at</th>
                </tr>
                {records}
            </table>
        )
    }

}

class ViewNotificationLogPage extends DetailPageBase {
    public PageTitle: string = '通知履歴';

    constructor(p: any, s: any) {
        super(p, s);
        const start = new Date();
        start.setDate(start.getDate() - 30);
        this.state = {
            start: start.toISOString().substr(0, 19),
            end: '',
            limit: '',
            notification_log: null,
            error: ''
        }
    }

    handleClick = () => {
        APIs.getInstance().getNotificationLog(this.state.start, this.state.end, this.state.limit)
            .then(json_ => {
                this.setState({
                    notificaiton_log: json_,
                    error: ''
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    notificaiton_log: null,
                    error: err.message
                })
            })
    }

    render() {
        return (
            <Page>
                <div>
                    検索期間指定(UTC)：
                    <input
                        onChange={(ev) => this.setState({ start: ev.target.value })}
                        value={this.state.start}
                        placeholder={'e.g. yyyy/mm/dd hh:MM:SS'}
                    ></input>
                    -
                    <input
                        onChange={(ev) => this.setState({ end: ev.target.value })}
                        value={this.state.end}
                        placeholder={'e.g. yyyy/mm/dd hh:MM:SS'}
                    ></input>
                </div>
                <div>
                    LIMIT:
                    <input
                        onChange={(ev) => { this.setState({ limit: ev.target.value }) }}
                        value={this.state.limit}
                    >
                    </input>
                </div>
                <div>
                    <button
                        onClick={this.handleClick}
                    >
                        検索
                    </button>
                </div>

                <div style={{ color: 'red' }}>{this.state.error}</div>

                <NotificationLogList
                    notificationLog={this.state.notificaiton_log}
                >
                </NotificationLogList>

            </Page>
        )
    }
}

class DashboardLinkPage extends DetailPageBase {
    public PageTitle = 'ダッシュボードを表示';
    render() {
        return (
            <Page>
                <div>ダッシュボードはPCのブラウザで表示して下さい</div>
                <div>Google Chromeに最適化しているため、Google Chrome以外での閲覧による不具合は対応できません</div>
                <Button
                    onClick={() => {
                        window.open(process.env.REACT_APP_DASHBOARD_PAGE_URL, '__blank')
                    }}
                >
                    ダッシュボードを表示する
                </Button>
            </Page>
        )
    }
}

export class SystemManagementPage extends DetailPageBase {
    public PageTitle: string = 'システム管理';
    constructor(p: any, s: any) {
        super(p, s);
    }
    pushViewNotificationLogPage = () => {
        this.props.navigator.pushPage({
            component: ViewNotificationLogPage,
            title: 'ViewNotificationLogPage'
        });
    }
    pushUserListPage = () => {
        this.props.navigator.pushPage({
            component: UserListPage,
            title: 'userListPage'
        });
    }
    pushKickUserPage = () => {
        this.props.navigator.pushPage({
            component: KickUserPage,
            title: 'KickUserPage'
        });
    }
    pushDashboardLinkPage = () => {
        this.props.navigator.pushPage({
            component: DashboardLinkPage,
            title: 'DashboardLinkPage'
        });
    }

    render() {
        return (
            <Page>
                <List>
                    <ListItem onClick={this.pushViewNotificationLogPage}>
                        <div className='left'>通知履歴閲覧</div>
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                    <ListItem onClick={this.pushUserListPage}>
                        <div className='left'>ユーザー一覧</div>
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                    <ListItem onClick={this.pushKickUserPage}>
                        <div className='left'>ログイン状態強制解除</div>
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                    <ListItem onClick={this.pushDashboardLinkPage}>
                        <div className='left'>ダッシュボードを表示</div>
                        <div className='right'>
                            <img src={icon_right_arrow} style={{ width: '1em', height: '1em' }}></img>
                        </div>
                    </ListItem>
                </List>
            </Page>
        )
    }
}